/* eslint-disable @typescript-eslint/no-explicit-any */
import { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core"
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T
> = { [_ in K]?: never }
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never
    }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string }
  String: { input: string; output: string }
  Boolean: { input: boolean; output: boolean }
  Int: { input: number; output: number }
  Float: { input: number; output: number }
  Date: { input: string; output: string }
  DateTime: { input: string; output: string }
  Json: { input: any; output: any }
}

export type Author = {
  __typename?: "Author"
  bio?: Maybe<Scalars["String"]["output"]>
  books?: Maybe<PaginatedBooks>
  id?: Maybe<Scalars["ID"]["output"]>
  images?: Maybe<AuthorImages>
  name?: Maybe<Scalars["String"]["output"]>
  placeOfBirth?: Maybe<Scalars["String"]["output"]>
  series?: Maybe<PaginatedSeries>
  slug?: Maybe<Scalars["String"]["output"]>
}

export type AuthorBooksArgs = {
  page?: InputMaybe<Scalars["Int"]["input"]>
  pageSize?: InputMaybe<Scalars["Int"]["input"]>
}

export type AuthorSeriesArgs = {
  page?: InputMaybe<Scalars["Int"]["input"]>
  pageSize?: InputMaybe<Scalars["Int"]["input"]>
}

export type AuthorImages = {
  __typename?: "AuthorImages"
  /** 660px wide */
  large?: Maybe<Scalars["String"]["output"]>
  /** 240px wide */
  small?: Maybe<Scalars["String"]["output"]>
}

export type Book = {
  __typename?: "Book"
  authors: Array<Author>
  averageRating?: Maybe<Scalars["Float"]["output"]>
  description?: Maybe<Scalars["String"]["output"]>
  id?: Maybe<Scalars["ID"]["output"]>
  images?: Maybe<BookImages>
  myRating?: Maybe<Rating>
  myReadingStatus?: Maybe<ReadingStatus>
  myReview?: Maybe<Review>
  myShelves: Array<Shelf>
  numPages?: Maybe<Scalars["Int"]["output"]>
  originalPublicationDate?: Maybe<Scalars["Date"]["output"]>
  positionInSeries?: Maybe<Scalars["Float"]["output"]>
  ratingsCount?: Maybe<Scalars["Json"]["output"]>
  reviews?: Maybe<PaginatedReviews>
  reviewsCount?: Maybe<Scalars["Int"]["output"]>
  seriesId?: Maybe<Scalars["ID"]["output"]>
  similarBooks: Array<Book>
  slug?: Maybe<Scalars["String"]["output"]>
  tags: Array<Tag>
  title?: Maybe<Scalars["String"]["output"]>
  totalRatingsCount?: Maybe<Scalars["Int"]["output"]>
}

export type BookReviewsArgs = {
  page?: InputMaybe<Scalars["Int"]["input"]>
  pageSize?: InputMaybe<Scalars["Int"]["input"]>
  sortBy?: InputMaybe<SortBy>
}

/** Search books with VNDB-style filters. */
export type BookFilters = {
  /** Min average rating. */
  averageRatingGte?: InputMaybe<Scalars["Float"]["input"]>
  /** Max average rating. */
  averageRatingLte?: InputMaybe<Scalars["Float"]["input"]>
  /** Exclude these tag slugs. */
  excludeTags?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>
  /** Must include all tag slugs. */
  includeTags?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>
  /** Min page count. */
  numPagesGte?: InputMaybe<Scalars["Int"]["input"]>
  /** Max page count. */
  numPagesLte?: InputMaybe<Scalars["Int"]["input"]>
  /** Published from year. */
  publishedAfterYear?: InputMaybe<Scalars["Int"]["input"]>
  /** Published until year. */
  publishedBeforeYear?: InputMaybe<Scalars["Int"]["input"]>
  /** Min total ratings. */
  totalRatingsCountGte?: InputMaybe<Scalars["Int"]["input"]>
  /** Max total ratings. */
  totalRatingsCountLte?: InputMaybe<Scalars["Int"]["input"]>
}

export type BookImages = {
  __typename?: "BookImages"
  /** 512x768 */
  large?: Maybe<Scalars["String"]["output"]>
  /** 256x384 */
  medium?: Maybe<Scalars["String"]["output"]>
  /** 128x192 */
  small?: Maybe<Scalars["String"]["output"]>
  /** 1024x1536 */
  xl?: Maybe<Scalars["String"]["output"]>
}

/** Sort books by various criteria. */
export enum BookSortOptions {
  /** Sort by average rating ascending. */
  AverageRatingAsc = "AVERAGE_RATING_ASC",
  /** Sort by average rating descending. */
  AverageRatingDesc = "AVERAGE_RATING_DESC",
  /** Sort by number of pages ascending. */
  NumPagesAsc = "NUM_PAGES_ASC",
  /** Sort by number of pages descending. */
  NumPagesDesc = "NUM_PAGES_DESC",
  /** Sort by publication date ascending. */
  PublicationDateAsc = "PUBLICATION_DATE_ASC",
  /** Sort by publication date descending. */
  PublicationDateDesc = "PUBLICATION_DATE_DESC",
  /** Sort by total ratings ascending. */
  TotalRatingsAsc = "TOTAL_RATINGS_ASC",
  /** Sort by total ratings descending. */
  TotalRatingsDesc = "TOTAL_RATINGS_DESC",
}

export type CsvImportCounts = {
  __typename?: "CsvImportCounts"
  ratings: Scalars["Int"]["output"]
  readingStatuses: Scalars["Int"]["output"]
  reviews: Scalars["Int"]["output"]
  shelves: Scalars["Int"]["output"]
  /** Number of books successfully processed. */
  successfulBooks: Scalars["Int"]["output"]
  /** Total number of books attempted to process. */
  totalBooks: Scalars["Int"]["output"]
}

export type CsvImportError = {
  __typename?: "CsvImportError"
  error?: Maybe<Scalars["String"]["output"]>
  line?: Maybe<Scalars["Int"]["output"]>
}

export type CsvImportResult = {
  __typename?: "CsvImportResult"
  counts?: Maybe<CsvImportCounts>
  errors?: Maybe<Array<Maybe<CsvImportError>>>
  status: CsvImportStatus
}

export enum CsvImportStatus {
  /** CSV import completed successfully. */
  Completed = "COMPLETED",
  /** CSV import failed. */
  Failed = "FAILED",
  /** CSV import is pending. */
  Pending = "PENDING",
  /** CSV import is in progress. */
  Processing = "PROCESSING",
}

export enum ImageFileType {
  /** User avatar image. */
  Avatar = "AVATAR",
  /** User banner image. */
  Banner = "BANNER",
}

export type PaginatedBooks = {
  __typename?: "PaginatedBooks"
  items: Array<Book>
  pagination: PaginationMeta
}

export type PaginatedReviewComments = {
  __typename?: "PaginatedReviewComments"
  items?: Maybe<Array<Maybe<ReviewComment>>>
  pagination?: Maybe<PaginationMeta>
}

export type PaginatedReviews = {
  __typename?: "PaginatedReviews"
  items?: Maybe<Array<Maybe<Review>>>
  pagination?: Maybe<PaginationMeta>
}

export type PaginatedSeries = {
  __typename?: "PaginatedSeries"
  items: Array<Series>
  pagination: PaginationMeta
}

export type PaginatedUserBooks = {
  __typename?: "PaginatedUserBooks"
  items: Array<UserBook>
  pagination: PaginationMeta
}

export type PaginationMeta = {
  __typename?: "PaginationMeta"
  page: Scalars["Int"]["output"]
  pageSize: Scalars["Int"]["output"]
  totalCount: Scalars["Int"]["output"]
  totalPages: Scalars["Int"]["output"]
}

export type Rating = {
  __typename?: "Rating"
  bookId?: Maybe<Scalars["ID"]["output"]>
  rating?: Maybe<Scalars["Int"]["output"]>
  userId?: Maybe<Scalars["ID"]["output"]>
}

export type ReadingStatus = {
  __typename?: "ReadingStatus"
  dateFinished?: Maybe<Scalars["Date"]["output"]>
  dateStarted?: Maybe<Scalars["Date"]["output"]>
  status?: Maybe<ReadingStatusEnum>
}

export enum ReadingStatusEnum {
  CurrentlyReading = "CURRENTLY_READING",
  DidNotFinish = "DID_NOT_FINISH",
  OnHold = "ON_HOLD",
  Priority = "PRIORITY",
  Read = "READ",
  WantToRead = "WANT_TO_READ",
}

export type RegisterUserInput = {
  avatarId?: InputMaybe<Scalars["ID"]["input"]>
  bannerId?: InputMaybe<Scalars["ID"]["input"]>
  displayName?: InputMaybe<Scalars["String"]["input"]>
  email: Scalars["String"]["input"]
  username?: InputMaybe<Scalars["String"]["input"]>
}

export type Review = {
  __typename?: "Review"
  book?: Maybe<Book>
  comments?: Maybe<PaginatedReviewComments>
  commentsCount?: Maybe<Scalars["Int"]["output"]>
  content?: Maybe<Scalars["Json"]["output"]>
  id?: Maybe<Scalars["ID"]["output"]>
  insertedAt?: Maybe<Scalars["DateTime"]["output"]>
  isEdited?: Maybe<Scalars["Boolean"]["output"]>
  isSpoiler?: Maybe<Scalars["Boolean"]["output"]>
  likedByMe?: Maybe<Scalars["Boolean"]["output"]>
  likesCount?: Maybe<Scalars["Int"]["output"]>
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>
  user?: Maybe<User>
}

export type ReviewCommentsArgs = {
  page?: InputMaybe<Scalars["Int"]["input"]>
  pageSize?: InputMaybe<Scalars["Int"]["input"]>
  sortBy?: InputMaybe<SortBy>
}

export type ReviewComment = {
  __typename?: "ReviewComment"
  content?: Maybe<Scalars["String"]["output"]>
  id?: Maybe<Scalars["ID"]["output"]>
  insertedAt?: Maybe<Scalars["DateTime"]["output"]>
  isEdited?: Maybe<Scalars["Boolean"]["output"]>
  likedByMe?: Maybe<Scalars["Boolean"]["output"]>
  likesCount?: Maybe<Scalars["Int"]["output"]>
  reviewId?: Maybe<Scalars["ID"]["output"]>
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>
  user?: Maybe<User>
}

export type RootMutationType = {
  __typename?: "RootMutationType"
  /** Add multiple books to multiple shelves. */
  addBooksToShelves?: Maybe<ShelfBooksUpdateResponse>
  /** Create a new rating */
  createRating?: Maybe<Rating>
  /** Create a new review */
  createReview?: Maybe<Review>
  /** Create a new review comment */
  createReviewComment?: Maybe<ReviewComment>
  /** Create a new shelf. */
  createShelf?: Maybe<Shelf>
  /** Create a new user profile. */
  createUser?: Maybe<User>
  /** Delete the current user's account and all related data. */
  deleteAccount?: Maybe<Scalars["Boolean"]["output"]>
  /** Remove the current reading status (along with dates read) for multiple books. */
  deleteReadingStatuses?: Maybe<StatusBooksUpdateResponse>
  /** Delete a review by me */
  deleteReview?: Maybe<Scalars["Boolean"]["output"]>
  /** Delete a review comment by me */
  deleteReviewComment?: Maybe<Scalars["Boolean"]["output"]>
  /** Delete a shelf */
  deleteShelf?: Maybe<Scalars["Boolean"]["output"]>
  /** Generate a presigned URL for uploading a file */
  generateUploadUrl?: Maybe<UploadUrlResponse>
  /** Like a review */
  likeReview?: Maybe<Scalars["Boolean"]["output"]>
  /** Like a review comment */
  likeReviewComment?: Maybe<Scalars["Boolean"]["output"]>
  /** Process the uploaded CSV file */
  processCsvUpload?: Maybe<CsvImportResult>
  /** Process the uploaded image file */
  processImageUpload?: Maybe<Scalars["Boolean"]["output"]>
  /** Remove multiple books from multiple shelves. */
  removeBooksFromShelves?: Maybe<ShelfBooksUpdateResponse>
  /** Remove my existing rating for a book */
  removeRating?: Maybe<Scalars["Boolean"]["output"]>
  /** Rename a shelf */
  renameShelf?: Maybe<Shelf>
  /** Reset the user's library by deleting all their reviews, ratings and shelves. */
  resetLibrary?: Maybe<Scalars["Boolean"]["output"]>
  /** Set the reading status for multiple books. Update or insert if not present. */
  setReadingStatuses?: Maybe<StatusBooksUpdateResponse>
  /** Unlike a review */
  unlikeReview?: Maybe<Scalars["Boolean"]["output"]>
  /** Unlike a review comment */
  unlikeReviewComment?: Maybe<Scalars["Boolean"]["output"]>
  /** Update my existing rating for a book */
  updateRating?: Maybe<Rating>
  /** Update an existing review by me */
  updateReview?: Maybe<Review>
  /** Update an existing review comment by me */
  updateReviewComment?: Maybe<ReviewComment>
  /** Update the current user's profile. */
  updateUser?: Maybe<User>
}

export type RootMutationTypeAddBooksToShelvesArgs = {
  bookIds: Array<Scalars["ID"]["input"]>
  shelfIds: Array<Scalars["ID"]["input"]>
}

export type RootMutationTypeCreateRatingArgs = {
  bookId: Scalars["ID"]["input"]
  rating: Scalars["Int"]["input"]
}

export type RootMutationTypeCreateReviewArgs = {
  bookId: Scalars["ID"]["input"]
  content: Scalars["Json"]["input"]
}

export type RootMutationTypeCreateReviewCommentArgs = {
  content: Scalars["String"]["input"]
  reviewId: Scalars["ID"]["input"]
}

export type RootMutationTypeCreateShelfArgs = {
  name: Scalars["String"]["input"]
}

export type RootMutationTypeCreateUserArgs = {
  input: RegisterUserInput
}

export type RootMutationTypeDeleteReadingStatusesArgs = {
  bookIds: Array<Scalars["ID"]["input"]>
}

export type RootMutationTypeDeleteReviewArgs = {
  id: Scalars["ID"]["input"]
}

export type RootMutationTypeDeleteReviewCommentArgs = {
  id: Scalars["ID"]["input"]
}

export type RootMutationTypeDeleteShelfArgs = {
  id: Scalars["ID"]["input"]
}

export type RootMutationTypeLikeReviewArgs = {
  reviewId: Scalars["ID"]["input"]
}

export type RootMutationTypeLikeReviewCommentArgs = {
  reviewCommentId: Scalars["ID"]["input"]
}

export type RootMutationTypeProcessCsvUploadArgs = {
  source: UploadSource
  uploadId: Scalars["ID"]["input"]
}

export type RootMutationTypeProcessImageUploadArgs = {
  imageType: ImageFileType
  uploadId: Scalars["ID"]["input"]
}

export type RootMutationTypeRemoveBooksFromShelvesArgs = {
  bookIds: Array<Scalars["ID"]["input"]>
  shelfIds: Array<Scalars["ID"]["input"]>
}

export type RootMutationTypeRemoveRatingArgs = {
  bookId: Scalars["ID"]["input"]
}

export type RootMutationTypeRenameShelfArgs = {
  id: Scalars["ID"]["input"]
  newName: Scalars["String"]["input"]
}

export type RootMutationTypeSetReadingStatusesArgs = {
  bookIds: Array<Scalars["ID"]["input"]>
  dateFinished?: InputMaybe<Scalars["Date"]["input"]>
  dateStarted?: InputMaybe<Scalars["Date"]["input"]>
  status: ReadingStatusEnum
}

export type RootMutationTypeUnlikeReviewArgs = {
  reviewId: Scalars["ID"]["input"]
}

export type RootMutationTypeUnlikeReviewCommentArgs = {
  reviewCommentId: Scalars["ID"]["input"]
}

export type RootMutationTypeUpdateRatingArgs = {
  bookId: Scalars["ID"]["input"]
  rating: Scalars["Int"]["input"]
}

export type RootMutationTypeUpdateReviewArgs = {
  content: Scalars["Json"]["input"]
  id: Scalars["ID"]["input"]
}

export type RootMutationTypeUpdateReviewCommentArgs = {
  content: Scalars["String"]["input"]
  id: Scalars["ID"]["input"]
}

export type RootMutationTypeUpdateUserArgs = {
  input: UpdateUserInput
}

export type RootQueryType = {
  __typename?: "RootQueryType"
  /** Get an author by ID. */
  author?: Maybe<Author>
  /** Get an author by slug. */
  authorBySlug?: Maybe<Author>
  /** Get a book by ID */
  book?: Maybe<Book>
  /** Get a book by slug */
  bookBySlug?: Maybe<Book>
  /** Browse books with various filters. */
  browseBooks?: Maybe<PaginatedBooks>
  /** Get other users, ranked by review count */
  discoverUsers?: Maybe<Array<Maybe<User>>>
  /** Check if an email is already taken */
  isEmailTaken?: Maybe<Scalars["Boolean"]["output"]>
  /** Get the currently authenticated user. */
  me?: Maybe<User>
  /** Get recent reviews */
  recentReviews?: Maybe<Array<Maybe<Review>>>
  /** Get a review by its ID */
  review?: Maybe<Review>
  /** Get a review comment by its ID */
  reviewComment?: Maybe<ReviewComment>
  /** Search for author by name. */
  searchAuthors?: Maybe<Array<Maybe<SearchAuthorResult>>>
  /** Search for books by title. */
  searchBooks?: Maybe<Array<Maybe<SearchBookResult>>>
  /** Get a series by ID */
  series?: Maybe<Series>
  /** Get a series by slug */
  seriesBySlug?: Maybe<Series>
  /** Get a tag by slug */
  tag?: Maybe<Tag>
  /** Get all tags. This is only for reference. */
  tags?: Maybe<Array<Maybe<Tag>>>
  /** Get top 10 books this week */
  trendingBooksWeekly?: Maybe<Array<Maybe<Book>>>
  /** Get trending reviews */
  trendingReviews?: Maybe<Array<Maybe<Review>>>
  /** Get a single user by their ID. */
  user?: Maybe<User>
  /** Get a single user by their username */
  userByUsername?: Maybe<User>
}

export type RootQueryTypeAuthorArgs = {
  id: Scalars["ID"]["input"]
}

export type RootQueryTypeAuthorBySlugArgs = {
  slug: Scalars["String"]["input"]
}

export type RootQueryTypeBookArgs = {
  id: Scalars["ID"]["input"]
}

export type RootQueryTypeBookBySlugArgs = {
  slug: Scalars["String"]["input"]
}

export type RootQueryTypeBrowseBooksArgs = {
  filters?: InputMaybe<BookFilters>
  page?: InputMaybe<Scalars["Int"]["input"]>
  pageSize?: InputMaybe<Scalars["Int"]["input"]>
  sortBy?: InputMaybe<BookSortOptions>
}

export type RootQueryTypeDiscoverUsersArgs = {
  limit?: Scalars["Int"]["input"]
}

export type RootQueryTypeIsEmailTakenArgs = {
  email: Scalars["String"]["input"]
}

export type RootQueryTypeReviewArgs = {
  id: Scalars["ID"]["input"]
}

export type RootQueryTypeReviewCommentArgs = {
  id: Scalars["ID"]["input"]
}

export type RootQueryTypeSearchAuthorsArgs = {
  name: Scalars["String"]["input"]
}

export type RootQueryTypeSearchBooksArgs = {
  title: Scalars["String"]["input"]
}

export type RootQueryTypeSeriesArgs = {
  id: Scalars["ID"]["input"]
}

export type RootQueryTypeSeriesBySlugArgs = {
  slug: Scalars["String"]["input"]
}

export type RootQueryTypeTagArgs = {
  slug: Scalars["String"]["input"]
}

export type RootQueryTypeUserArgs = {
  id: Scalars["ID"]["input"]
}

export type RootQueryTypeUserByUsernameArgs = {
  username: Scalars["String"]["input"]
}

export type SearchAuthorResult = {
  __typename?: "SearchAuthorResult"
  id?: Maybe<Scalars["ID"]["output"]>
  images?: Maybe<AuthorImages>
  name?: Maybe<Scalars["String"]["output"]>
}

export type SearchBookResult = {
  __typename?: "SearchBookResult"
  authors?: Maybe<Scalars["String"]["output"]>
  id?: Maybe<Scalars["ID"]["output"]>
  images?: Maybe<BookImages>
  slug?: Maybe<Scalars["String"]["output"]>
  title?: Maybe<Scalars["String"]["output"]>
  totalRatingsCount?: Maybe<Scalars["Int"]["output"]>
}

export type Series = {
  __typename?: "Series"
  authors: Array<Author>
  books?: Maybe<PaginatedBooks>
  description?: Maybe<Scalars["String"]["output"]>
  id?: Maybe<Scalars["ID"]["output"]>
  name?: Maybe<Scalars["String"]["output"]>
  slug?: Maybe<Scalars["String"]["output"]>
}

export type SeriesBooksArgs = {
  page?: InputMaybe<Scalars["Int"]["input"]>
  pageSize?: InputMaybe<Scalars["Int"]["input"]>
}

export type Shelf = {
  __typename?: "Shelf"
  /** Get books on this shelf with the owner's reading status, rating, and review */
  books?: Maybe<PaginatedUserBooks>
  booksCount: Scalars["Int"]["output"]
  id: Scalars["ID"]["output"]
  name: Scalars["String"]["output"]
  slug: Scalars["String"]["output"]
  userId: Scalars["ID"]["output"]
}

export type ShelfBooksArgs = {
  page?: InputMaybe<Scalars["Int"]["input"]>
  pageSize?: InputMaybe<Scalars["Int"]["input"]>
}

/** Response after a bulk shelf-book update. */
export type ShelfBooksUpdateResponse = {
  __typename?: "ShelfBooksUpdateResponse"
  /** Number of book-shelf entries that were actually inserted or removed. */
  booksAffected: Scalars["Int"]["output"]
  /** Any error messages or reasons some items were skipped. */
  errors?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>
  /** Number of shelves that were actually changed. */
  shelvesAffected: Scalars["Int"]["output"]
  /** Indicates whether the operation succeeded overall. */
  success: Scalars["Boolean"]["output"]
}

export type SocialLinks = {
  __typename?: "SocialLinks"
  instagram?: Maybe<Scalars["String"]["output"]>
  tiktok?: Maybe<Scalars["String"]["output"]>
  twitter?: Maybe<Scalars["String"]["output"]>
  website?: Maybe<Scalars["String"]["output"]>
}

export type SocialLinksInput = {
  instagram?: InputMaybe<Scalars["String"]["input"]>
  tiktok?: InputMaybe<Scalars["String"]["input"]>
  twitter?: InputMaybe<Scalars["String"]["input"]>
  website?: InputMaybe<Scalars["String"]["input"]>
}

export enum SortBy {
  /** Sort by most liked first */
  MostLiked = "MOST_LIKED",
  /** Sort by newest first */
  Newest = "NEWEST",
  /** Sort by oldest first */
  Oldest = "OLDEST",
  /** Sort by trending first */
  Trending = "TRENDING",
}

/** Response after updating books for a status */
export type StatusBooksUpdateResponse = {
  __typename?: "StatusBooksUpdateResponse"
  /** Number of books successfully updated. */
  successfulBooks: Scalars["Int"]["output"]
  /** Total number of books processed during the update. */
  totalBooksProcessed: Scalars["Int"]["output"]
}

export type Tag = {
  __typename?: "Tag"
  description?: Maybe<Scalars["String"]["output"]>
  name?: Maybe<Scalars["String"]["output"]>
  slug?: Maybe<Scalars["String"]["output"]>
}

export type UpdateUserInput = {
  avatarId?: InputMaybe<Scalars["ID"]["input"]>
  bannerId?: InputMaybe<Scalars["ID"]["input"]>
  bio?: InputMaybe<Scalars["String"]["input"]>
  displayName?: InputMaybe<Scalars["String"]["input"]>
  email?: InputMaybe<Scalars["String"]["input"]>
  favoriteAuthors?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>
  favoriteBooks?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>
  socialLinks?: InputMaybe<SocialLinksInput>
  username?: InputMaybe<Scalars["String"]["input"]>
}

export enum UploadSource {
  /** Goodreads CSV export. */
  Goodreads = "GOODREADS",
  /** StoryGraph CSV export. */
  Storygraph = "STORYGRAPH",
}

export type UploadUrlResponse = {
  __typename?: "UploadUrlResponse"
  uploadId: Scalars["ID"]["output"]
  uploadUrl: Scalars["String"]["output"]
}

export type User = {
  __typename?: "User"
  avatarId?: Maybe<Scalars["ID"]["output"]>
  averageRating?: Maybe<Scalars["Float"]["output"]>
  bannerId?: Maybe<Scalars["ID"]["output"]>
  bio?: Maybe<Scalars["String"]["output"]>
  displayName?: Maybe<Scalars["String"]["output"]>
  favoriteAuthors?: Maybe<Array<Maybe<Author>>>
  favoriteBooks?: Maybe<Array<Maybe<Book>>>
  id?: Maybe<Scalars["ID"]["output"]>
  insertedAt?: Maybe<Scalars["DateTime"]["output"]>
  /** Get all books in the user's library, including ratings, reviews, and reading status. */
  libraryBooks?: Maybe<PaginatedUserBooks>
  /** Get books from the library filtered by reading status, with ratings, reviews, and reading status. */
  libraryBooksByStatus?: Maybe<PaginatedUserBooks>
  /** Get a user's rating for a book by book ID */
  ratingForBook?: Maybe<Rating>
  ratingsCount?: Maybe<Scalars["Json"]["output"]>
  /** Get a user's review for a book slug (needs username too from parent object) */
  reviewForBook?: Maybe<Review>
  /** Get all reviews by a specific user */
  reviews?: Maybe<PaginatedReviews>
  reviewsCount?: Maybe<Scalars["Int"]["output"]>
  /** Get a specific shelf by slug */
  shelf?: Maybe<Shelf>
  /** Get all shelves for the user */
  shelves?: Maybe<Array<Maybe<Shelf>>>
  socialLinks?: Maybe<SocialLinks>
  totalRatingsCount?: Maybe<Scalars["Int"]["output"]>
  username?: Maybe<Scalars["String"]["output"]>
}

export type UserLibraryBooksArgs = {
  page?: InputMaybe<Scalars["Int"]["input"]>
  pageSize?: InputMaybe<Scalars["Int"]["input"]>
}

export type UserLibraryBooksByStatusArgs = {
  page?: InputMaybe<Scalars["Int"]["input"]>
  pageSize?: InputMaybe<Scalars["Int"]["input"]>
  status?: InputMaybe<ReadingStatusEnum>
}

export type UserRatingForBookArgs = {
  bookId: Scalars["ID"]["input"]
}

export type UserReviewForBookArgs = {
  bookSlug: Scalars["String"]["input"]
}

export type UserReviewsArgs = {
  page?: InputMaybe<Scalars["Int"]["input"]>
  pageSize?: InputMaybe<Scalars["Int"]["input"]>
  sortBy?: InputMaybe<SortBy>
}

export type UserShelfArgs = {
  slug: Scalars["String"]["input"]
}

/** A book with the user's reading status, rating, and review. */
export type UserBook = {
  __typename?: "UserBook"
  book: Book
  rating?: Maybe<Rating>
  readingStatus?: Maybe<ReadingStatus>
  review?: Maybe<Review>
  shelves?: Maybe<Array<Maybe<Shelf>>>
}

export type FavAuthorFieldsFragment = {
  __typename: "Author"
  id?: string | null
  slug?: string | null
  name?: string | null
  images?: {
    __typename?: "AuthorImages"
    large?: string | null
    small?: string | null
  } | null
}

export type BookBasicInfoFieldsFragment = {
  __typename: "Book"
  id?: string | null
  slug?: string | null
  title?: string | null
  authors: Array<{
    __typename?: "Author"
    name?: string | null
    slug?: string | null
    id?: string | null
  }>
  images?: {
    __typename?: "BookImages"
    large?: string | null
    medium?: string | null
    small?: string | null
    xl?: string | null
  } | null
}

export type BookReviewCardFieldsFragment = {
  __typename: "Book"
  id?: string | null
  slug?: string | null
  title?: string | null
  images?: {
    __typename?: "BookImages"
    large?: string | null
    medium?: string | null
    small?: string | null
    xl?: string | null
  } | null
  myReadingStatus?: {
    __typename?: "ReadingStatus"
    status?: ReadingStatusEnum | null
  } | null
  reviews?: {
    __typename?: "PaginatedReviews"
    items?: Array<{
      __typename?: "Review"
      id?: string | null
      commentsCount?: number | null
    } | null> | null
  } | null
}

export type FavBooksFieldsFragment = {
  __typename?: "Book"
  averageRating?: number | null
  description?: string | null
  id?: string | null
  originalPublicationDate?: string | null
  positionInSeries?: number | null
  ratingsCount?: any | null
  reviewsCount?: number | null
  seriesId?: string | null
  slug?: string | null
  title?: string | null
  myReadingStatus?: {
    __typename?: "ReadingStatus"
    status?: ReadingStatusEnum | null
  } | null
  images?: {
    __typename?: "BookImages"
    large?: string | null
    medium?: string | null
    small?: string | null
    xl?: string | null
  } | null
}

export type PaginationMetaFieldsFragment = {
  __typename?: "PaginationMeta"
  page: number
  pageSize: number
  totalCount: number
  totalPages: number
}

export type ReviewCommentFieldsFragment = {
  __typename: "ReviewComment"
  content?: string | null
  id?: string | null
  insertedAt?: string | null
  isEdited?: boolean | null
  likedByMe?: boolean | null
  likesCount?: number | null
  reviewId?: string | null
  user?: {
    __typename?: "User"
    id?: string | null
    username?: string | null
    avatarId?: string | null
  } | null
}

export type ReviewFieldsFragment = {
  __typename: "Review"
  commentsCount?: number | null
  content?: any | null
  id?: string | null
  insertedAt?: string | null
  isEdited?: boolean | null
  likesCount?: number | null
  likedByMe?: boolean | null
  user?: {
    __typename?: "User"
    id?: string | null
    username?: string | null
    avatarId?: string | null
  } | null
  book?: {
    __typename?: "Book"
    id?: string | null
    title?: string | null
    slug?: string | null
    myReadingStatus?: {
      __typename?: "ReadingStatus"
      status?: ReadingStatusEnum | null
    } | null
    myShelves: Array<{
      __typename?: "Shelf"
      id: string
      name: string
      slug: string
      booksCount: number
      userId: string
    }>
    images?: {
      __typename?: "BookImages"
      small?: string | null
      medium?: string | null
      large?: string | null
      xl?: string | null
    } | null
  } | null
}

export type PaginatedUserBookFieldsFragment = {
  __typename?: "PaginatedUserBooks"
  items: Array<{
    __typename?: "UserBook"
    rating?: { __typename?: "Rating"; rating?: number | null } | null
    review?: { __typename: "Review"; id?: string | null } | null
    readingStatus?: {
      __typename?: "ReadingStatus"
      status?: ReadingStatusEnum | null
      dateStarted?: string | null
      dateFinished?: string | null
    } | null
    book: {
      __typename?: "Book"
      totalRatingsCount?: number | null
      id?: string | null
      title?: string | null
      slug?: string | null
      authors: Array<{
        __typename?: "Author"
        name?: string | null
        slug?: string | null
        id?: string | null
      }>
      images?: {
        __typename?: "BookImages"
        large?: string | null
        medium?: string | null
        small?: string | null
        xl?: string | null
      } | null
    }
    shelves?: Array<{
      __typename?: "Shelf"
      userId: string
      name: string
      slug: string
      id: string
      booksCount: number
    } | null> | null
  }>
  pagination: {
    __typename?: "PaginationMeta"
    page: number
    pageSize: number
    totalCount: number
    totalPages: number
  }
}

export type ShelfFieldsFragment = {
  __typename?: "Shelf"
  userId: string
  name: string
  slug: string
  id: string
  booksCount: number
}

export type UserBookFieldsFragment = {
  __typename?: "UserBook"
  rating?: { __typename?: "Rating"; rating?: number | null } | null
  review?: { __typename: "Review"; id?: string | null } | null
  readingStatus?: {
    __typename?: "ReadingStatus"
    status?: ReadingStatusEnum | null
    dateStarted?: string | null
    dateFinished?: string | null
  } | null
  book: {
    __typename?: "Book"
    totalRatingsCount?: number | null
    id?: string | null
    title?: string | null
    slug?: string | null
    authors: Array<{
      __typename?: "Author"
      name?: string | null
      slug?: string | null
      id?: string | null
    }>
    images?: {
      __typename?: "BookImages"
      large?: string | null
      medium?: string | null
      small?: string | null
      xl?: string | null
    } | null
  }
  shelves?: Array<{
    __typename?: "Shelf"
    userId: string
    name: string
    slug: string
    id: string
    booksCount: number
  } | null> | null
}

export type UserFieldsFragment = {
  __typename: "User"
  id?: string | null
  bio?: string | null
  displayName?: string | null
  insertedAt?: string | null
  ratingsCount?: any | null
  totalRatingsCount?: number | null
  averageRating?: number | null
  username?: string | null
  socialLinks?: {
    __typename?: "SocialLinks"
    instagram?: string | null
    tiktok?: string | null
    twitter?: string | null
    website?: string | null
  } | null
}

export type CreateUserMutationVariables = Exact<{
  input: RegisterUserInput
}>

export type CreateUserMutation = {
  __typename?: "RootMutationType"
  createUser?: { __typename?: "User"; id?: string | null } | null
}

export type DeleteAccountMutationVariables = Exact<{ [key: string]: never }>

export type DeleteAccountMutation = {
  __typename?: "RootMutationType"
  deleteAccount?: boolean | null
}

export type ResetLibraryMutationVariables = Exact<{ [key: string]: never }>

export type ResetLibraryMutation = {
  __typename?: "RootMutationType"
  resetLibrary?: boolean | null
}

export type CreateRatingMutationVariables = Exact<{
  bookId: Scalars["ID"]["input"]
  rating: Scalars["Int"]["input"]
}>

export type CreateRatingMutation = {
  __typename?: "RootMutationType"
  createRating?: {
    __typename: "Rating"
    bookId?: string | null
    rating?: number | null
    userId?: string | null
  } | null
}

export type CreateReviewMutationVariables = Exact<{
  bookId: Scalars["ID"]["input"]
  content: Scalars["Json"]["input"]
}>

export type CreateReviewMutation = {
  __typename?: "RootMutationType"
  createReview?: {
    __typename?: "Review"
    commentsCount?: number | null
    content?: any | null
    id?: string | null
    isEdited?: boolean | null
    insertedAt?: string | null
    likesCount?: number | null
    likedByMe?: boolean | null
    book?: {
      __typename?: "Book"
      title?: string | null
      id?: string | null
      slug?: string | null
    } | null
    user?: { __typename?: "User"; id?: string | null } | null
  } | null
}

export type CreateReviewCommentMutationVariables = Exact<{
  content: Scalars["String"]["input"]
  reviewId: Scalars["ID"]["input"]
}>

export type CreateReviewCommentMutation = {
  __typename?: "RootMutationType"
  createReviewComment?: {
    __typename?: "ReviewComment"
    content?: string | null
    id?: string | null
    insertedAt?: string | null
    likesCount?: number | null
    likedByMe?: boolean | null
    isEdited?: boolean | null
    reviewId?: string | null
    user?: {
      __typename?: "User"
      id?: string | null
      username?: string | null
      avatarId?: string | null
    } | null
  } | null
}

export type DeleteReviewMutationVariables = Exact<{
  id: Scalars["ID"]["input"]
}>

export type DeleteReviewMutation = {
  __typename?: "RootMutationType"
  deleteReview?: boolean | null
}

export type DeleteReviewCommentMutationVariables = Exact<{
  id: Scalars["ID"]["input"]
}>

export type DeleteReviewCommentMutation = {
  __typename?: "RootMutationType"
  deleteReviewComment?: boolean | null
}

export type LikeReviewMutationVariables = Exact<{
  reviewId: Scalars["ID"]["input"]
}>

export type LikeReviewMutation = {
  __typename?: "RootMutationType"
  likeReview?: boolean | null
}

export type LikeReviewCommentMutationVariables = Exact<{
  reviewCommentId: Scalars["ID"]["input"]
}>

export type LikeReviewCommentMutation = {
  __typename?: "RootMutationType"
  likeReviewComment?: boolean | null
}

export type UnlikeReviewMutationVariables = Exact<{
  reviewId: Scalars["ID"]["input"]
}>

export type UnlikeReviewMutation = {
  __typename?: "RootMutationType"
  unlikeReview?: boolean | null
}

export type UnlikeReviewCommentMutationVariables = Exact<{
  reviewCommentId: Scalars["ID"]["input"]
}>

export type UnlikeReviewCommentMutation = {
  __typename?: "RootMutationType"
  unlikeReviewComment?: boolean | null
}

export type UpdateRatingMutationVariables = Exact<{
  bookId: Scalars["ID"]["input"]
  rating: Scalars["Int"]["input"]
}>

export type UpdateRatingMutation = {
  __typename?: "RootMutationType"
  updateRating?: {
    __typename: "Rating"
    bookId?: string | null
    rating?: number | null
    userId?: string | null
  } | null
}

export type UpdateReviewMutationVariables = Exact<{
  content: Scalars["Json"]["input"]
  id: Scalars["ID"]["input"]
}>

export type UpdateReviewMutation = {
  __typename?: "RootMutationType"
  updateReview?: {
    __typename?: "Review"
    commentsCount?: number | null
    content?: any | null
    id?: string | null
    isEdited?: boolean | null
    insertedAt?: string | null
    likesCount?: number | null
    likedByMe?: boolean | null
    book?: {
      __typename?: "Book"
      id?: string | null
      title?: string | null
      slug?: string | null
    } | null
    user?: { __typename?: "User"; id?: string | null } | null
  } | null
}

export type UpdateReviewCommentMutationVariables = Exact<{
  id: Scalars["ID"]["input"]
  content: Scalars["String"]["input"]
}>

export type UpdateReviewCommentMutation = {
  __typename?: "RootMutationType"
  updateReviewComment?: {
    __typename?: "ReviewComment"
    id?: string | null
    content?: string | null
    insertedAt?: string | null
    isEdited?: boolean | null
  } | null
}

export type AddBooksToShelfMutationVariables = Exact<{
  bookIds: Array<Scalars["ID"]["input"]> | Scalars["ID"]["input"]
  shelfIds: Array<Scalars["ID"]["input"]> | Scalars["ID"]["input"]
}>

export type AddBooksToShelfMutation = {
  __typename?: "RootMutationType"
  addBooksToShelves?: {
    __typename?: "ShelfBooksUpdateResponse"
    booksAffected: number
    shelvesAffected: number
    success: boolean
  } | null
}

export type CreateShelfMutationVariables = Exact<{
  name: Scalars["String"]["input"]
}>

export type CreateShelfMutation = {
  __typename?: "RootMutationType"
  createShelf?: {
    __typename?: "Shelf"
    id: string
    name: string
    slug: string
    userId: string
    booksCount: number
  } | null
}

export type DeleteReadingStatusesMutationVariables = Exact<{
  bookIds: Array<Scalars["ID"]["input"]> | Scalars["ID"]["input"]
}>

export type DeleteReadingStatusesMutation = {
  __typename?: "RootMutationType"
  deleteReadingStatuses?: {
    __typename?: "StatusBooksUpdateResponse"
    successfulBooks: number
    totalBooksProcessed: number
  } | null
}

export type DeleteShelfMutationVariables = Exact<{
  id: Scalars["ID"]["input"]
}>

export type DeleteShelfMutation = {
  __typename?: "RootMutationType"
  deleteShelf?: boolean | null
}

export type RemoveBooksFromShelfMutationVariables = Exact<{
  bookIds: Array<Scalars["ID"]["input"]> | Scalars["ID"]["input"]
  shelfIds: Array<Scalars["ID"]["input"]> | Scalars["ID"]["input"]
}>

export type RemoveBooksFromShelfMutation = {
  __typename?: "RootMutationType"
  removeBooksFromShelves?: {
    __typename?: "ShelfBooksUpdateResponse"
    booksAffected: number
    shelvesAffected: number
    success: boolean
  } | null
}

export type RenameShelfMutationVariables = Exact<{
  id: Scalars["ID"]["input"]
  newName: Scalars["String"]["input"]
}>

export type RenameShelfMutation = {
  __typename?: "RootMutationType"
  renameShelf?: {
    __typename?: "Shelf"
    id: string
    name: string
    slug: string
    userId: string
    booksCount: number
  } | null
}

export type SetReadingStatusesMutationVariables = Exact<{
  bookIds: Array<Scalars["ID"]["input"]> | Scalars["ID"]["input"]
  status: ReadingStatusEnum
  dateStarted?: InputMaybe<Scalars["Date"]["input"]>
  dateFinished?: InputMaybe<Scalars["Date"]["input"]>
}>

export type SetReadingStatusesMutation = {
  __typename?: "RootMutationType"
  setReadingStatuses?: {
    __typename: "StatusBooksUpdateResponse"
    successfulBooks: number
    totalBooksProcessed: number
  } | null
}

export type GenerateUploadUrlMutationVariables = Exact<{ [key: string]: never }>

export type GenerateUploadUrlMutation = {
  __typename?: "RootMutationType"
  generateUploadUrl?: {
    __typename?: "UploadUrlResponse"
    uploadId: string
    uploadUrl: string
  } | null
}

export type ProcessCsvUploadMutationVariables = Exact<{
  source: UploadSource
  uploadId: Scalars["ID"]["input"]
}>

export type ProcessCsvUploadMutation = {
  __typename?: "RootMutationType"
  processCsvUpload?: {
    __typename?: "CsvImportResult"
    status: CsvImportStatus
    counts?: {
      __typename?: "CsvImportCounts"
      successfulBooks: number
      ratings: number
      readingStatuses: number
      reviews: number
      shelves: number
      totalBooks: number
    } | null
    errors?: Array<{
      __typename?: "CsvImportError"
      error?: string | null
      line?: number | null
    } | null> | null
  } | null
}

export type ProcessImageUploadMutationVariables = Exact<{
  imageType: ImageFileType
  uploadId: Scalars["ID"]["input"]
}>

export type ProcessImageUploadMutation = {
  __typename?: "RootMutationType"
  processImageUpload?: boolean | null
}

export type UpdateUserMutationVariables = Exact<{
  input: UpdateUserInput
}>

export type UpdateUserMutation = {
  __typename?: "RootMutationType"
  updateUser?: {
    __typename?: "User"
    bio?: string | null
    displayName?: string | null
    id?: string | null
    username?: string | null
    socialLinks?: {
      __typename?: "SocialLinks"
      instagram?: string | null
      tiktok?: string | null
      twitter?: string | null
      website?: string | null
    } | null
    favoriteAuthors?: Array<{
      __typename?: "Author"
      id?: string | null
      name?: string | null
      slug?: string | null
    } | null> | null
    favoriteBooks?: Array<{
      __typename?: "Book"
      id?: string | null
      slug?: string | null
      title?: string | null
      images?: {
        __typename?: "BookImages"
        large?: string | null
        medium?: string | null
        small?: string | null
        xl?: string | null
      } | null
    } | null> | null
  } | null
}

export type AuthorBySlugQueryVariables = Exact<{
  slug: Scalars["String"]["input"]
  pageSize?: InputMaybe<Scalars["Int"]["input"]>
  page?: InputMaybe<Scalars["Int"]["input"]>
}>

export type AuthorBySlugQuery = {
  __typename?: "RootQueryType"
  authorBySlug?: {
    __typename?: "Author"
    id?: string | null
    name?: string | null
    placeOfBirth?: string | null
    bio?: string | null
    slug?: string | null
    images?: {
      __typename?: "AuthorImages"
      large?: string | null
      small?: string | null
    } | null
    books?: {
      __typename?: "PaginatedBooks"
      pagination: {
        __typename?: "PaginationMeta"
        page: number
        pageSize: number
        totalCount: number
        totalPages: number
      }
      items: Array<{
        __typename?: "Book"
        title?: string | null
        totalRatingsCount?: number | null
        averageRating?: number | null
        reviewsCount?: number | null
        slug?: string | null
        id?: string | null
        myReadingStatus?: {
          __typename?: "ReadingStatus"
          status?: ReadingStatusEnum | null
        } | null
        myShelves: Array<{
          __typename?: "Shelf"
          id: string
          name: string
          slug: string
          userId: string
          booksCount: number
        }>
        images?: {
          __typename?: "BookImages"
          large?: string | null
          medium?: string | null
          small?: string | null
          xl?: string | null
        } | null
        authors: Array<{
          __typename?: "Author"
          id?: string | null
          name?: string | null
          slug?: string | null
        }>
      }>
    } | null
    series?: {
      __typename: "PaginatedSeries"
      pagination: {
        __typename?: "PaginationMeta"
        page: number
        pageSize: number
        totalCount: number
        totalPages: number
      }
      items: Array<{
        __typename?: "Series"
        description?: string | null
        id?: string | null
        name?: string | null
        slug?: string | null
        books?: {
          __typename?: "PaginatedBooks"
          items: Array<{
            __typename?: "Book"
            slug?: string | null
            title?: string | null
            images?: {
              __typename?: "BookImages"
              large?: string | null
              medium?: string | null
              small?: string | null
              xl?: string | null
            } | null
          }>
        } | null
      }>
    } | null
  } | null
}

export type AuthorMetadataQueryVariables = Exact<{
  slug: Scalars["String"]["input"]
}>

export type AuthorMetadataQuery = {
  __typename?: "RootQueryType"
  authorBySlug?: {
    __typename?: "Author"
    id?: string | null
    name?: string | null
    bio?: string | null
    slug?: string | null
    images?: { __typename?: "AuthorImages"; small?: string | null } | null
  } | null
}

export type MyFavoriteAuthorsQueryVariables = Exact<{ [key: string]: never }>

export type MyFavoriteAuthorsQuery = {
  __typename?: "RootQueryType"
  me?: {
    __typename?: "User"
    id?: string | null
    favoriteAuthors?: Array<{
      __typename?: "Author"
      id?: string | null
      name?: string | null
    } | null> | null
  } | null
}

export type SearchAuthorsQueryVariables = Exact<{
  name: Scalars["String"]["input"]
}>

export type SearchAuthorsQuery = {
  __typename?: "RootQueryType"
  searchAuthors?: Array<{
    __typename?: "SearchAuthorResult"
    id?: string | null
    name?: string | null
    images?: {
      __typename?: "AuthorImages"
      small?: string | null
      large?: string | null
    } | null
  } | null> | null
}

export type BookByIdQueryVariables = Exact<{
  id: Scalars["ID"]["input"]
}>

export type BookByIdQuery = {
  __typename?: "RootQueryType"
  book?: {
    __typename: "Book"
    id?: string | null
    slug?: string | null
    title?: string | null
    images?: {
      __typename?: "BookImages"
      large?: string | null
      medium?: string | null
      small?: string | null
      xl?: string | null
    } | null
    myReadingStatus?: {
      __typename?: "ReadingStatus"
      status?: ReadingStatusEnum | null
    } | null
    reviews?: {
      __typename?: "PaginatedReviews"
      items?: Array<{
        __typename?: "Review"
        id?: string | null
        commentsCount?: number | null
      } | null> | null
    } | null
  } | null
}

export type BookBySlugQueryVariables = Exact<{
  slug: Scalars["String"]["input"]
}>

export type BookBySlugQuery = {
  __typename?: "RootQueryType"
  bookBySlug?: {
    __typename: "Book"
    averageRating?: number | null
    description?: string | null
    totalRatingsCount?: number | null
    id?: string | null
    numPages?: number | null
    originalPublicationDate?: string | null
    positionInSeries?: number | null
    ratingsCount?: any | null
    reviewsCount?: number | null
    seriesId?: string | null
    slug?: string | null
    title?: string | null
    tags: Array<{
      __typename?: "Tag"
      name?: string | null
      slug?: string | null
    }>
    authors: Array<{
      __typename?: "Author"
      name?: string | null
      slug?: string | null
      id?: string | null
    }>
    images?: {
      __typename?: "BookImages"
      large?: string | null
      medium?: string | null
      small?: string | null
      xl?: string | null
    } | null
    myShelves: Array<{
      __typename?: "Shelf"
      id: string
      booksCount: number
      name: string
      slug: string
      userId: string
    }>
    myReadingStatus?: {
      __typename?: "ReadingStatus"
      status?: ReadingStatusEnum | null
    } | null
    myRating?: { __typename?: "Rating"; rating?: number | null } | null
    myReview?: { __typename?: "Review"; id?: string | null } | null
    similarBooks: Array<{
      __typename?: "Book"
      id?: string | null
      title?: string | null
      slug?: string | null
      images?: {
        __typename?: "BookImages"
        large?: string | null
        medium?: string | null
        small?: string | null
        xl?: string | null
      } | null
    }>
  } | null
}

export type BookInfoBasicBySlugQueryVariables = Exact<{
  slug: Scalars["String"]["input"]
}>

export type BookInfoBasicBySlugQuery = {
  __typename?: "RootQueryType"
  bookBySlug?: {
    __typename: "Book"
    id?: string | null
    slug?: string | null
    title?: string | null
    authors: Array<{
      __typename?: "Author"
      name?: string | null
      slug?: string | null
      id?: string | null
    }>
    images?: {
      __typename?: "BookImages"
      large?: string | null
      medium?: string | null
      small?: string | null
      xl?: string | null
    } | null
  } | null
}

export type BookMetadataQueryVariables = Exact<{
  slug: Scalars["String"]["input"]
}>

export type BookMetadataQuery = {
  __typename?: "RootQueryType"
  bookBySlug?: {
    __typename: "Book"
    description?: string | null
    id?: string | null
    slug?: string | null
    title?: string | null
    authors: Array<{
      __typename?: "Author"
      id?: string | null
      name?: string | null
    }>
  } | null
}

export type BrowseBooksQueryVariables = Exact<{
  filters?: InputMaybe<BookFilters>
  page?: InputMaybe<Scalars["Int"]["input"]>
  pageSize?: InputMaybe<Scalars["Int"]["input"]>
  sortBy?: InputMaybe<BookSortOptions>
}>

export type BrowseBooksQuery = {
  __typename?: "RootQueryType"
  browseBooks?: {
    __typename?: "PaginatedBooks"
    pagination: {
      __typename?: "PaginationMeta"
      page: number
      pageSize: number
      totalPages: number
      totalCount: number
    }
    items: Array<{
      __typename?: "Book"
      title?: string | null
      slug?: string | null
      id?: string | null
      numPages?: number | null
      totalRatingsCount?: number | null
      averageRating?: number | null
      originalPublicationDate?: string | null
      images?: {
        __typename?: "BookImages"
        large?: string | null
        medium?: string | null
        small?: string | null
        xl?: string | null
      } | null
      authors: Array<{
        __typename?: "Author"
        id?: string | null
        name?: string | null
        slug?: string | null
      }>
      myReadingStatus?: {
        __typename?: "ReadingStatus"
        status?: ReadingStatusEnum | null
      } | null
    }>
  } | null
}

export type FavoriteBooksByUsernameQueryVariables = Exact<{
  username: Scalars["String"]["input"]
}>

export type FavoriteBooksByUsernameQuery = {
  __typename?: "RootQueryType"
  userByUsername?: {
    __typename: "User"
    id?: string | null
    favoriteBooks?: Array<{
      __typename?: "Book"
      averageRating?: number | null
      description?: string | null
      id?: string | null
      originalPublicationDate?: string | null
      positionInSeries?: number | null
      ratingsCount?: any | null
      reviewsCount?: number | null
      seriesId?: string | null
      slug?: string | null
      title?: string | null
      myReadingStatus?: {
        __typename?: "ReadingStatus"
        status?: ReadingStatusEnum | null
      } | null
      images?: {
        __typename?: "BookImages"
        large?: string | null
        medium?: string | null
        small?: string | null
        xl?: string | null
      } | null
    } | null> | null
  } | null
}

export type HomeDataQueryVariables = Exact<{ [key: string]: never }>

export type HomeDataQuery = {
  __typename?: "RootQueryType"
  recentReviews?: Array<{
    __typename?: "Review"
    id?: string | null
    user?: {
      __typename?: "User"
      id?: string | null
      username?: string | null
    } | null
    book?: {
      __typename?: "Book"
      id?: string | null
      slug?: string | null
      title?: string | null
      images?: {
        __typename?: "BookImages"
        large?: string | null
        medium?: string | null
        small?: string | null
        xl?: string | null
      } | null
    } | null
  } | null> | null
  trendingBooksWeekly?: Array<{
    __typename?: "Book"
    id?: string | null
    slug?: string | null
    title?: string | null
    images?: {
      __typename?: "BookImages"
      large?: string | null
      medium?: string | null
      small?: string | null
      xl?: string | null
    } | null
    authors: Array<{
      __typename?: "Author"
      id?: string | null
      name?: string | null
    }>
  } | null> | null
  browseBooks?: {
    __typename?: "PaginatedBooks"
    items: Array<{
      __typename?: "Book"
      id?: string | null
      slug?: string | null
      images?: {
        __typename?: "BookImages"
        large?: string | null
        medium?: string | null
        small?: string | null
        xl?: string | null
      } | null
    }>
  } | null
}

export type MyFavoriteBooksQueryVariables = Exact<{ [key: string]: never }>

export type MyFavoriteBooksQuery = {
  __typename?: "RootQueryType"
  me?: {
    __typename?: "User"
    id?: string | null
    favoriteBooks?: Array<{
      __typename?: "Book"
      id?: string | null
      title?: string | null
      images?: {
        __typename?: "BookImages"
        large?: string | null
        medium?: string | null
        small?: string | null
        xl?: string | null
      } | null
    } | null> | null
  } | null
}

export type SearchBooksByTitleQueryVariables = Exact<{
  title: Scalars["String"]["input"]
}>

export type SearchBooksByTitleQuery = {
  __typename?: "RootQueryType"
  searchBooks?: Array<{
    __typename?: "SearchBookResult"
    id?: string | null
    slug?: string | null
    title?: string | null
    authors?: string | null
    images?: {
      __typename?: "BookImages"
      xl?: string | null
      large?: string | null
      small?: string | null
      medium?: string | null
    } | null
  } | null> | null
}

export type TopRatedBooksQueryVariables = Exact<{
  page?: InputMaybe<Scalars["Int"]["input"]>
  pageSize?: InputMaybe<Scalars["Int"]["input"]>
}>

export type TopRatedBooksQuery = {
  __typename?: "RootQueryType"
  browseBooks?: {
    __typename?: "PaginatedBooks"
    items: Array<{
      __typename?: "Book"
      averageRating?: number | null
      description?: string | null
      id?: string | null
      originalPublicationDate?: string | null
      positionInSeries?: number | null
      ratingsCount?: any | null
      totalRatingsCount?: number | null
      reviewsCount?: number | null
      seriesId?: string | null
      slug?: string | null
      title?: string | null
      images?: {
        __typename?: "BookImages"
        large?: string | null
        medium?: string | null
        small?: string | null
        xl?: string | null
      } | null
      myReadingStatus?: {
        __typename?: "ReadingStatus"
        status?: ReadingStatusEnum | null
      } | null
      myShelves: Array<{
        __typename?: "Shelf"
        booksCount: number
        id: string
        name: string
        slug: string
        userId: string
      }>
      authors: Array<{
        __typename?: "Author"
        id?: string | null
        slug?: string | null
        name?: string | null
      }>
    }>
    pagination: {
      __typename?: "PaginationMeta"
      page: number
      pageSize: number
      totalCount: number
      totalPages: number
    }
  } | null
}

export type SingleReviewMetadataQueryVariables = Exact<{
  bookSlug: Scalars["String"]["input"]
  username: Scalars["String"]["input"]
}>

export type SingleReviewMetadataQuery = {
  __typename?: "RootQueryType"
  userByUsername?: {
    __typename?: "User"
    id?: string | null
    username?: string | null
    reviewForBook?: {
      __typename?: "Review"
      book?: {
        __typename?: "Book"
        title?: string | null
        id?: string | null
      } | null
    } | null
  } | null
}

export type SingleReviewPageQueryVariables = Exact<{
  bookSlug: Scalars["String"]["input"]
  username: Scalars["String"]["input"]
}>

export type SingleReviewPageQuery = {
  __typename?: "RootQueryType"
  userByUsername?: {
    __typename: "User"
    id?: string | null
    avatarId?: string | null
    reviewForBook?: {
      __typename: "Review"
      commentsCount?: number | null
      content?: any | null
      id?: string | null
      insertedAt?: string | null
      isEdited?: boolean | null
      likesCount?: number | null
      likedByMe?: boolean | null
      book?: {
        __typename?: "Book"
        id?: string | null
        title?: string | null
        slug?: string | null
        authors: Array<{
          __typename?: "Author"
          name?: string | null
          slug?: string | null
          id?: string | null
        }>
        myReadingStatus?: {
          __typename?: "ReadingStatus"
          status?: ReadingStatusEnum | null
        } | null
        myShelves: Array<{
          __typename?: "Shelf"
          id: string
          name: string
          slug: string
          booksCount: number
          userId: string
        }>
        images?: {
          __typename?: "BookImages"
          small?: string | null
          medium?: string | null
          large?: string | null
          xl?: string | null
        } | null
      } | null
      user?: {
        __typename?: "User"
        id?: string | null
        username?: string | null
        avatarId?: string | null
      } | null
    } | null
  } | null
}

export type BookReviewsBySlugQueryVariables = Exact<{
  slug: Scalars["String"]["input"]
  page: Scalars["Int"]["input"]
  pageSize: Scalars["Int"]["input"]
  sortBy?: InputMaybe<SortBy>
}>

export type BookReviewsBySlugQuery = {
  __typename?: "RootQueryType"
  bookBySlug?: {
    __typename: "Book"
    id?: string | null
    averageRating?: number | null
    totalRatingsCount?: number | null
    reviews?: {
      __typename?: "PaginatedReviews"
      items?: Array<{
        __typename: "Review"
        commentsCount?: number | null
        content?: any | null
        id?: string | null
        insertedAt?: string | null
        isEdited?: boolean | null
        likesCount?: number | null
        likedByMe?: boolean | null
        comments?: {
          __typename: "PaginatedReviewComments"
          items?: Array<{
            __typename: "ReviewComment"
            content?: string | null
            id?: string | null
            insertedAt?: string | null
            isEdited?: boolean | null
            likedByMe?: boolean | null
            likesCount?: number | null
            reviewId?: string | null
            user?: {
              __typename?: "User"
              id?: string | null
              username?: string | null
              avatarId?: string | null
            } | null
          } | null> | null
        } | null
        user?: {
          __typename?: "User"
          id?: string | null
          username?: string | null
          avatarId?: string | null
        } | null
        book?: {
          __typename?: "Book"
          id?: string | null
          title?: string | null
          slug?: string | null
          myReadingStatus?: {
            __typename?: "ReadingStatus"
            status?: ReadingStatusEnum | null
          } | null
          myShelves: Array<{
            __typename?: "Shelf"
            id: string
            name: string
            slug: string
            booksCount: number
            userId: string
          }>
          images?: {
            __typename?: "BookImages"
            small?: string | null
            medium?: string | null
            large?: string | null
            xl?: string | null
          } | null
        } | null
      } | null> | null
      pagination?: {
        __typename?: "PaginationMeta"
        page: number
        pageSize: number
        totalCount: number
        totalPages: number
      } | null
    } | null
  } | null
}

export type MyReviewForBookQueryVariables = Exact<{
  slug: Scalars["String"]["input"]
}>

export type MyReviewForBookQuery = {
  __typename?: "RootQueryType"
  bookBySlug?: {
    __typename: "Book"
    slug?: string | null
    title?: string | null
    id?: string | null
    authors: Array<{
      __typename?: "Author"
      id?: string | null
      name?: string | null
      slug?: string | null
    }>
    images?: {
      __typename?: "BookImages"
      large?: string | null
      medium?: string | null
      small?: string | null
      xl?: string | null
    } | null
    myReadingStatus?: {
      __typename?: "ReadingStatus"
      status?: ReadingStatusEnum | null
    } | null
    myRating?: { __typename?: "Rating"; rating?: number | null } | null
    myShelves: Array<{
      __typename?: "Shelf"
      id: string
      name: string
      slug: string
      userId: string
      booksCount: number
    }>
    similarBooks: Array<{ __typename?: "Book"; id?: string | null }>
    myReview?: {
      __typename?: "Review"
      commentsCount?: number | null
      content?: any | null
      id?: string | null
      insertedAt?: string | null
      isEdited?: boolean | null
      likesCount?: number | null
      likedByMe?: boolean | null
      user?: { __typename?: "User"; id?: string | null } | null
      book?: {
        __typename?: "Book"
        id?: string | null
        title?: string | null
        slug?: string | null
      } | null
    } | null
  } | null
}

export type GetReviewQueryVariables = Exact<{
  id: Scalars["ID"]["input"]
}>

export type GetReviewQuery = {
  __typename?: "RootQueryType"
  review?: {
    __typename?: "Review"
    commentsCount?: number | null
    content?: any | null
    id?: string | null
    insertedAt?: string | null
    isEdited?: boolean | null
    likedByMe?: boolean | null
    likesCount?: number | null
    book?: {
      __typename?: "Book"
      id?: string | null
      title?: string | null
      slug?: string | null
    } | null
    user?: { __typename?: "User"; id?: string | null } | null
    comments?: {
      __typename?: "PaginatedReviewComments"
      items?: Array<{
        __typename?: "ReviewComment"
        content?: string | null
        id?: string | null
        insertedAt?: string | null
        isEdited?: boolean | null
        likedByMe?: boolean | null
        likesCount?: number | null
        reviewId?: string | null
        user?: { __typename?: "User"; id?: string | null } | null
      } | null> | null
    } | null
  } | null
}

export type ReviewCommentsQueryVariables = Exact<{
  id: Scalars["ID"]["input"]
  page?: InputMaybe<Scalars["Int"]["input"]>
  pageSize?: InputMaybe<Scalars["Int"]["input"]>
  sortBy?: InputMaybe<SortBy>
}>

export type ReviewCommentsQuery = {
  __typename?: "RootQueryType"
  review?: {
    __typename: "Review"
    id?: string | null
    commentsCount?: number | null
    comments?: {
      __typename: "PaginatedReviewComments"
      items?: Array<{
        __typename: "ReviewComment"
        content?: string | null
        id?: string | null
        insertedAt?: string | null
        isEdited?: boolean | null
        likedByMe?: boolean | null
        likesCount?: number | null
        reviewId?: string | null
        user?: {
          __typename?: "User"
          id?: string | null
          username?: string | null
          avatarId?: string | null
        } | null
      } | null> | null
      pagination?: {
        __typename?: "PaginationMeta"
        page: number
        pageSize: number
        totalCount: number
        totalPages: number
      } | null
    } | null
  } | null
}

export type ReviewCommentsCountQueryVariables = Exact<{
  id: Scalars["ID"]["input"]
}>

export type ReviewCommentsCountQuery = {
  __typename?: "RootQueryType"
  review?: {
    __typename: "Review"
    id?: string | null
    commentsCount?: number | null
  } | null
}

export type TrendingReviewsWeeklyQueryVariables = Exact<{
  [key: string]: never
}>

export type TrendingReviewsWeeklyQuery = {
  __typename?: "RootQueryType"
  trendingReviews?: Array<{
    __typename: "Review"
    commentsCount?: number | null
    content?: any | null
    id?: string | null
    insertedAt?: string | null
    isEdited?: boolean | null
    likesCount?: number | null
    likedByMe?: boolean | null
    user?: {
      __typename?: "User"
      id?: string | null
      username?: string | null
      avatarId?: string | null
    } | null
    book?: {
      __typename?: "Book"
      id?: string | null
      title?: string | null
      slug?: string | null
      myReadingStatus?: {
        __typename?: "ReadingStatus"
        status?: ReadingStatusEnum | null
      } | null
      myShelves: Array<{
        __typename?: "Shelf"
        id: string
        name: string
        slug: string
        booksCount: number
        userId: string
      }>
      images?: {
        __typename?: "BookImages"
        small?: string | null
        medium?: string | null
        large?: string | null
        xl?: string | null
      } | null
    } | null
  } | null> | null
}

export type UserRatingForBookQueryVariables = Exact<{
  bookId: Scalars["ID"]["input"]
  userId: Scalars["ID"]["input"]
}>

export type UserRatingForBookQuery = {
  __typename?: "RootQueryType"
  user?: {
    __typename: "User"
    id?: string | null
    ratingForBook?: {
      __typename?: "Rating"
      rating?: number | null
      userId?: string | null
      bookId?: string | null
    } | null
  } | null
}

export type UserReviewForBookQueryVariables = Exact<{
  bookSlug: Scalars["String"]["input"]
  username: Scalars["String"]["input"]
}>

export type UserReviewForBookQuery = {
  __typename?: "RootQueryType"
  userByUsername?: {
    __typename: "User"
    id?: string | null
    reviewForBook?: {
      __typename: "Review"
      commentsCount?: number | null
      content?: any | null
      id?: string | null
      insertedAt?: string | null
      isEdited?: boolean | null
      likesCount?: number | null
      likedByMe?: boolean | null
      user?: {
        __typename?: "User"
        id?: string | null
        username?: string | null
        avatarId?: string | null
      } | null
      book?: {
        __typename?: "Book"
        id?: string | null
        title?: string | null
        slug?: string | null
        myReadingStatus?: {
          __typename?: "ReadingStatus"
          status?: ReadingStatusEnum | null
        } | null
        myShelves: Array<{
          __typename?: "Shelf"
          id: string
          name: string
          slug: string
          booksCount: number
          userId: string
        }>
        images?: {
          __typename?: "BookImages"
          small?: string | null
          medium?: string | null
          large?: string | null
          xl?: string | null
        } | null
      } | null
    } | null
  } | null
}

export type UserReviewsSortByQueryVariables = Exact<{
  username: Scalars["String"]["input"]
  page?: InputMaybe<Scalars["Int"]["input"]>
  pageSize?: InputMaybe<Scalars["Int"]["input"]>
  sortBy?: InputMaybe<SortBy>
}>

export type UserReviewsSortByQuery = {
  __typename?: "RootQueryType"
  userByUsername?: {
    __typename: "User"
    id?: string | null
    username?: string | null
    reviews?: {
      __typename?: "PaginatedReviews"
      items?: Array<{
        __typename: "Review"
        commentsCount?: number | null
        content?: any | null
        id?: string | null
        insertedAt?: string | null
        isEdited?: boolean | null
        likesCount?: number | null
        likedByMe?: boolean | null
        user?: {
          __typename?: "User"
          id?: string | null
          username?: string | null
          avatarId?: string | null
        } | null
        book?: {
          __typename?: "Book"
          id?: string | null
          title?: string | null
          slug?: string | null
          myReadingStatus?: {
            __typename?: "ReadingStatus"
            status?: ReadingStatusEnum | null
          } | null
          myShelves: Array<{
            __typename?: "Shelf"
            id: string
            name: string
            slug: string
            booksCount: number
            userId: string
          }>
          images?: {
            __typename?: "BookImages"
            small?: string | null
            medium?: string | null
            large?: string | null
            xl?: string | null
          } | null
        } | null
      } | null> | null
      pagination?: {
        __typename?: "PaginationMeta"
        page: number
        pageSize: number
        totalCount: number
        totalPages: number
      } | null
    } | null
  } | null
}

export type SeriesBySlugQueryVariables = Exact<{
  slug: Scalars["String"]["input"]
  page?: InputMaybe<Scalars["Int"]["input"]>
  pageSize?: InputMaybe<Scalars["Int"]["input"]>
}>

export type SeriesBySlugQuery = {
  __typename?: "RootQueryType"
  seriesBySlug?: {
    __typename: "Series"
    description?: string | null
    id?: string | null
    name?: string | null
    slug?: string | null
    authors: Array<{
      __typename?: "Author"
      id?: string | null
      name?: string | null
      slug?: string | null
    }>
    books?: {
      __typename?: "PaginatedBooks"
      items: Array<{
        __typename: "Book"
        averageRating?: number | null
        id?: string | null
        positionInSeries?: number | null
        reviewsCount?: number | null
        seriesId?: string | null
        slug?: string | null
        title?: string | null
        totalRatingsCount?: number | null
        authors: Array<{
          __typename?: "Author"
          id?: string | null
          name?: string | null
          slug?: string | null
        }>
        images?: {
          __typename?: "BookImages"
          large?: string | null
          medium?: string | null
          small?: string | null
          xl?: string | null
        } | null
        myReadingStatus?: {
          __typename?: "ReadingStatus"
          status?: ReadingStatusEnum | null
        } | null
        myShelves: Array<{
          __typename?: "Shelf"
          booksCount: number
          id: string
          name: string
          slug: string
          userId: string
        }>
        similarBooks: Array<{ __typename?: "Book"; id?: string | null }>
      }>
      pagination: {
        __typename?: "PaginationMeta"
        page: number
        pageSize: number
        totalCount: number
        totalPages: number
      }
    } | null
  } | null
}

export type SeriesMetadataQueryVariables = Exact<{
  slug: Scalars["String"]["input"]
}>

export type SeriesMetadataQuery = {
  __typename?: "RootQueryType"
  seriesBySlug?: {
    __typename: "Series"
    description?: string | null
    id?: string | null
    name?: string | null
    slug?: string | null
    authors: Array<{
      __typename?: "Author"
      id?: string | null
      name?: string | null
    }>
  } | null
}

export type SeriesPreviewQueryVariables = Exact<{
  id: Scalars["ID"]["input"]
}>

export type SeriesPreviewQuery = {
  __typename?: "RootQueryType"
  series?: {
    __typename?: "Series"
    id?: string | null
    name?: string | null
    slug?: string | null
    books?: {
      __typename?: "PaginatedBooks"
      items: Array<{
        __typename?: "Book"
        id?: string | null
        positionInSeries?: number | null
        slug?: string | null
        title?: string | null
        images?: {
          __typename?: "BookImages"
          large?: string | null
          medium?: string | null
          small?: string | null
          xl?: string | null
        } | null
        authors: Array<{
          __typename?: "Author"
          name?: string | null
          id?: string | null
        }>
      }>
    } | null
  } | null
}

export type AllBooksByStatusQueryVariables = Exact<{
  username: Scalars["String"]["input"]
}>

export type AllBooksByStatusQuery = {
  __typename?: "RootQueryType"
  currentlyReading?: {
    __typename: "User"
    id?: string | null
    libraryBooksByStatus?: {
      __typename?: "PaginatedUserBooks"
      items: Array<{
        __typename?: "UserBook"
        book: {
          __typename?: "Book"
          title?: string | null
          slug?: string | null
          id?: string | null
          images?: {
            __typename?: "BookImages"
            large?: string | null
            medium?: string | null
            small?: string | null
            xl?: string | null
          } | null
        }
      }>
    } | null
  } | null
  read?: {
    __typename: "User"
    id?: string | null
    libraryBooksByStatus?: {
      __typename?: "PaginatedUserBooks"
      items: Array<{
        __typename?: "UserBook"
        book: {
          __typename?: "Book"
          title?: string | null
          slug?: string | null
          id?: string | null
          images?: {
            __typename?: "BookImages"
            large?: string | null
            medium?: string | null
            small?: string | null
            xl?: string | null
          } | null
        }
      }>
    } | null
  } | null
  wantToRead?: {
    __typename: "User"
    id?: string | null
    libraryBooksByStatus?: {
      __typename?: "PaginatedUserBooks"
      items: Array<{
        __typename?: "UserBook"
        book: {
          __typename?: "Book"
          title?: string | null
          slug?: string | null
          id?: string | null
          images?: {
            __typename?: "BookImages"
            large?: string | null
            medium?: string | null
            small?: string | null
            xl?: string | null
          } | null
        }
      }>
    } | null
  } | null
  priority?: {
    __typename: "User"
    id?: string | null
    libraryBooksByStatus?: {
      __typename?: "PaginatedUserBooks"
      items: Array<{
        __typename?: "UserBook"
        book: {
          __typename?: "Book"
          title?: string | null
          slug?: string | null
          id?: string | null
          images?: {
            __typename?: "BookImages"
            large?: string | null
            medium?: string | null
            small?: string | null
            xl?: string | null
          } | null
        }
      }>
    } | null
  } | null
  onHold?: {
    __typename: "User"
    id?: string | null
    libraryBooksByStatus?: {
      __typename?: "PaginatedUserBooks"
      items: Array<{
        __typename?: "UserBook"
        book: {
          __typename?: "Book"
          title?: string | null
          slug?: string | null
          id?: string | null
          images?: {
            __typename?: "BookImages"
            large?: string | null
            medium?: string | null
            small?: string | null
            xl?: string | null
          } | null
        }
      }>
    } | null
  } | null
}

export type AllBooksInLibraryQueryVariables = Exact<{
  username: Scalars["String"]["input"]
  page?: InputMaybe<Scalars["Int"]["input"]>
  pageSize?: InputMaybe<Scalars["Int"]["input"]>
}>

export type AllBooksInLibraryQuery = {
  __typename?: "RootQueryType"
  userByUsername?: {
    __typename: "User"
    id?: string | null
    libraryBooks?: {
      __typename?: "PaginatedUserBooks"
      items: Array<{
        __typename?: "UserBook"
        rating?: { __typename?: "Rating"; rating?: number | null } | null
        review?: { __typename: "Review"; id?: string | null } | null
        readingStatus?: {
          __typename?: "ReadingStatus"
          status?: ReadingStatusEnum | null
          dateStarted?: string | null
          dateFinished?: string | null
        } | null
        book: {
          __typename?: "Book"
          totalRatingsCount?: number | null
          id?: string | null
          title?: string | null
          slug?: string | null
          authors: Array<{
            __typename?: "Author"
            name?: string | null
            slug?: string | null
            id?: string | null
          }>
          images?: {
            __typename?: "BookImages"
            large?: string | null
            medium?: string | null
            small?: string | null
            xl?: string | null
          } | null
        }
        shelves?: Array<{
          __typename?: "Shelf"
          userId: string
          name: string
          slug: string
          id: string
          booksCount: number
        } | null> | null
      }>
      pagination: {
        __typename?: "PaginationMeta"
        page: number
        pageSize: number
        totalCount: number
        totalPages: number
      }
    } | null
  } | null
}

export type BooksByCustomShelfSlugQueryVariables = Exact<{
  username: Scalars["String"]["input"]
  slug: Scalars["String"]["input"]
  page?: InputMaybe<Scalars["Int"]["input"]>
  pageSize?: InputMaybe<Scalars["Int"]["input"]>
}>

export type BooksByCustomShelfSlugQuery = {
  __typename?: "RootQueryType"
  userByUsername?: {
    __typename: "User"
    id?: string | null
    shelf?: {
      __typename?: "Shelf"
      id: string
      slug: string
      books?: {
        __typename?: "PaginatedUserBooks"
        items: Array<{
          __typename?: "UserBook"
          rating?: { __typename?: "Rating"; rating?: number | null } | null
          review?: { __typename: "Review"; id?: string | null } | null
          readingStatus?: {
            __typename?: "ReadingStatus"
            status?: ReadingStatusEnum | null
            dateStarted?: string | null
            dateFinished?: string | null
          } | null
          book: {
            __typename?: "Book"
            totalRatingsCount?: number | null
            id?: string | null
            title?: string | null
            slug?: string | null
            authors: Array<{
              __typename?: "Author"
              name?: string | null
              slug?: string | null
              id?: string | null
            }>
            images?: {
              __typename?: "BookImages"
              large?: string | null
              medium?: string | null
              small?: string | null
              xl?: string | null
            } | null
          }
          shelves?: Array<{
            __typename?: "Shelf"
            userId: string
            name: string
            slug: string
            id: string
            booksCount: number
          } | null> | null
        }>
        pagination: {
          __typename?: "PaginationMeta"
          page: number
          pageSize: number
          totalCount: number
          totalPages: number
        }
      } | null
    } | null
  } | null
}

export type BooksByStatusQueryVariables = Exact<{
  username: Scalars["String"]["input"]
  status: ReadingStatusEnum
  page?: InputMaybe<Scalars["Int"]["input"]>
  pageSize?: InputMaybe<Scalars["Int"]["input"]>
}>

export type BooksByStatusQuery = {
  __typename?: "RootQueryType"
  userByUsername?: {
    __typename: "User"
    id?: string | null
    libraryBooksByStatus?: {
      __typename?: "PaginatedUserBooks"
      items: Array<{
        __typename?: "UserBook"
        rating?: { __typename?: "Rating"; rating?: number | null } | null
        review?: { __typename: "Review"; id?: string | null } | null
        readingStatus?: {
          __typename?: "ReadingStatus"
          status?: ReadingStatusEnum | null
          dateStarted?: string | null
          dateFinished?: string | null
        } | null
        book: {
          __typename?: "Book"
          totalRatingsCount?: number | null
          id?: string | null
          title?: string | null
          slug?: string | null
          authors: Array<{
            __typename?: "Author"
            name?: string | null
            slug?: string | null
            id?: string | null
          }>
          images?: {
            __typename?: "BookImages"
            large?: string | null
            medium?: string | null
            small?: string | null
            xl?: string | null
          } | null
        }
        shelves?: Array<{
          __typename?: "Shelf"
          userId: string
          name: string
          slug: string
          id: string
          booksCount: number
        } | null> | null
      }>
      pagination: {
        __typename?: "PaginationMeta"
        page: number
        pageSize: number
        totalCount: number
        totalPages: number
      }
    } | null
  } | null
}

export type CustomShelfLabelQueryVariables = Exact<{
  username: Scalars["String"]["input"]
  slug: Scalars["String"]["input"]
}>

export type CustomShelfLabelQuery = {
  __typename?: "RootQueryType"
  userByUsername?: {
    __typename: "User"
    id?: string | null
    shelf?: { __typename: "Shelf"; id: string; name: string } | null
  } | null
}

export type CustomShelvesQueryVariables = Exact<{
  username: Scalars["String"]["input"]
}>

export type CustomShelvesQuery = {
  __typename?: "RootQueryType"
  userByUsername?: {
    __typename: "User"
    id?: string | null
    username?: string | null
    shelves?: Array<{
      __typename?: "Shelf"
      id: string
      name: string
      slug: string
      userId: string
      booksCount: number
    } | null> | null
  } | null
}

export type CustomShelvesBooksQueryVariables = Exact<{
  username: Scalars["String"]["input"]
}>

export type CustomShelvesBooksQuery = {
  __typename?: "RootQueryType"
  userByUsername?: {
    __typename: "User"
    id?: string | null
    username?: string | null
    shelves?: Array<{
      __typename?: "Shelf"
      id: string
      name: string
      slug: string
      userId: string
      books?: {
        __typename?: "PaginatedUserBooks"
        items: Array<{
          __typename?: "UserBook"
          book: {
            __typename?: "Book"
            id?: string | null
            title?: string | null
            slug?: string | null
            myRating?: { __typename?: "Rating"; rating?: number | null } | null
            authors: Array<{
              __typename?: "Author"
              name?: string | null
              id?: string | null
              slug?: string | null
            }>
            images?: {
              __typename?: "BookImages"
              large?: string | null
              medium?: string | null
              small?: string | null
              xl?: string | null
            } | null
          }
        }>
        pagination: {
          __typename?: "PaginationMeta"
          page: number
          pageSize: number
          totalCount: number
          totalPages: number
        }
      } | null
    } | null> | null
  } | null
}

export type LibraryMobileCustomShelfCoversQueryVariables = Exact<{
  username: Scalars["String"]["input"]
}>

export type LibraryMobileCustomShelfCoversQuery = {
  __typename?: "RootQueryType"
  userByUsername?: {
    __typename: "User"
    id?: string | null
    shelves?: Array<{
      __typename?: "Shelf"
      slug: string
      name: string
      books?: {
        __typename?: "PaginatedUserBooks"
        items: Array<{
          __typename?: "UserBook"
          book: {
            __typename?: "Book"
            id?: string | null
            images?: {
              __typename?: "BookImages"
              large?: string | null
              medium?: string | null
              small?: string | null
              xl?: string | null
            } | null
          }
        }>
      } | null
    } | null> | null
  } | null
}

export type LibraryMobileShelfCoversByStatusQueryVariables = Exact<{
  username: Scalars["String"]["input"]
}>

export type LibraryMobileShelfCoversByStatusQuery = {
  __typename?: "RootQueryType"
  userByUsername?: {
    __typename: "User"
    id?: string | null
    currentlyReading?: {
      __typename?: "PaginatedUserBooks"
      items: Array<{
        __typename?: "UserBook"
        book: {
          __typename?: "Book"
          id?: string | null
          images?: {
            __typename?: "BookImages"
            large?: string | null
            medium?: string | null
            small?: string | null
            xl?: string | null
          } | null
        }
      }>
    } | null
    didNotFinish?: {
      __typename?: "PaginatedUserBooks"
      items: Array<{
        __typename?: "UserBook"
        book: {
          __typename?: "Book"
          id?: string | null
          images?: {
            __typename?: "BookImages"
            large?: string | null
            medium?: string | null
            small?: string | null
            xl?: string | null
          } | null
        }
      }>
    } | null
    onHold?: {
      __typename?: "PaginatedUserBooks"
      items: Array<{
        __typename?: "UserBook"
        book: {
          __typename?: "Book"
          id?: string | null
          images?: {
            __typename?: "BookImages"
            large?: string | null
            medium?: string | null
            small?: string | null
            xl?: string | null
          } | null
        }
      }>
    } | null
    priority?: {
      __typename?: "PaginatedUserBooks"
      items: Array<{
        __typename?: "UserBook"
        book: {
          __typename?: "Book"
          id?: string | null
          images?: {
            __typename?: "BookImages"
            large?: string | null
            medium?: string | null
            small?: string | null
            xl?: string | null
          } | null
        }
      }>
    } | null
    read?: {
      __typename?: "PaginatedUserBooks"
      items: Array<{
        __typename?: "UserBook"
        book: {
          __typename?: "Book"
          id?: string | null
          images?: {
            __typename?: "BookImages"
            large?: string | null
            medium?: string | null
            small?: string | null
            xl?: string | null
          } | null
        }
      }>
    } | null
    wantToRead?: {
      __typename?: "PaginatedUserBooks"
      items: Array<{
        __typename?: "UserBook"
        book: {
          __typename?: "Book"
          id?: string | null
          images?: {
            __typename?: "BookImages"
            large?: string | null
            medium?: string | null
            small?: string | null
            xl?: string | null
          } | null
        }
      }>
    } | null
    libraryBooks?: {
      __typename?: "PaginatedUserBooks"
      items: Array<{
        __typename?: "UserBook"
        book: {
          __typename?: "Book"
          id?: string | null
          images?: {
            __typename?: "BookImages"
            large?: string | null
            medium?: string | null
            small?: string | null
            xl?: string | null
          } | null
        }
      }>
    } | null
  } | null
}

export type MyBooksByStatusQueryVariables = Exact<{
  status: ReadingStatusEnum
  page: Scalars["Int"]["input"]
  pageSize: Scalars["Int"]["input"]
}>

export type MyBooksByStatusQuery = {
  __typename?: "RootQueryType"
  me?: {
    __typename: "User"
    id?: string | null
    libraryBooksByStatus?: {
      __typename?: "PaginatedUserBooks"
      items: Array<{
        __typename?: "UserBook"
        book: {
          __typename?: "Book"
          id?: string | null
          slug?: string | null
          images?: {
            __typename?: "BookImages"
            large?: string | null
            medium?: string | null
            small?: string | null
            xl?: string | null
          } | null
        }
      }>
    } | null
  } | null
}

export type MyCountByStatusQueryVariables = Exact<{ [key: string]: never }>

export type MyCountByStatusQuery = {
  __typename?: "RootQueryType"
  me?: {
    __typename: "User"
    id?: string | null
    currentlyReading?: {
      __typename?: "PaginatedUserBooks"
      pagination: { __typename?: "PaginationMeta"; totalCount: number }
    } | null
    read?: {
      __typename?: "PaginatedUserBooks"
      pagination: { __typename?: "PaginationMeta"; totalCount: number }
    } | null
    wantToRead?: {
      __typename?: "PaginatedUserBooks"
      pagination: { __typename?: "PaginationMeta"; totalCount: number }
    } | null
    priority?: {
      __typename?: "PaginatedUserBooks"
      pagination: { __typename?: "PaginationMeta"; totalCount: number }
    } | null
    onHold?: {
      __typename?: "PaginatedUserBooks"
      pagination: { __typename?: "PaginationMeta"; totalCount: number }
    } | null
    didNotFinish?: {
      __typename?: "PaginatedUserBooks"
      pagination: { __typename?: "PaginationMeta"; totalCount: number }
    } | null
  } | null
}

export type MyCustomShelvesQueryVariables = Exact<{ [key: string]: never }>

export type MyCustomShelvesQuery = {
  __typename?: "RootQueryType"
  me?: {
    __typename: "User"
    id?: string | null
    username?: string | null
    shelves?: Array<{
      __typename?: "Shelf"
      id: string
      name: string
      slug: string
      userId: string
      booksCount: number
    } | null> | null
  } | null
}

export type UserStatusCountsQueryVariables = Exact<{
  username: Scalars["String"]["input"]
}>

export type UserStatusCountsQuery = {
  __typename?: "RootQueryType"
  userByUsername?: {
    __typename: "User"
    id?: string | null
    currentlyReading?: {
      __typename?: "PaginatedUserBooks"
      pagination: { __typename?: "PaginationMeta"; totalCount: number }
    } | null
    read?: {
      __typename?: "PaginatedUserBooks"
      pagination: { __typename?: "PaginationMeta"; totalCount: number }
    } | null
    wantToRead?: {
      __typename?: "PaginatedUserBooks"
      pagination: { __typename?: "PaginationMeta"; totalCount: number }
    } | null
    priority?: {
      __typename?: "PaginatedUserBooks"
      pagination: { __typename?: "PaginationMeta"; totalCount: number }
    } | null
    onHold?: {
      __typename?: "PaginatedUserBooks"
      pagination: { __typename?: "PaginationMeta"; totalCount: number }
    } | null
    didNotFinish?: {
      __typename?: "PaginatedUserBooks"
      pagination: { __typename?: "PaginationMeta"; totalCount: number }
    } | null
  } | null
}

export type BooksReadByUserQueryVariables = Exact<{
  username: Scalars["String"]["input"]
}>

export type BooksReadByUserQuery = {
  __typename?: "RootQueryType"
  userByUsername?: {
    __typename: "User"
    id?: string | null
    libraryBooksByStatus?: {
      __typename?: "PaginatedUserBooks"
      pagination: { __typename?: "PaginationMeta"; totalCount: number }
    } | null
  } | null
}

export type DiscoverUsersQueryVariables = Exact<{
  limit: Scalars["Int"]["input"]
}>

export type DiscoverUsersQuery = {
  __typename?: "RootQueryType"
  discoverUsers?: Array<{
    __typename?: "User"
    avatarId?: string | null
    id?: string | null
    username?: string | null
    displayName?: string | null
    reviews?: {
      __typename?: "PaginatedReviews"
      pagination?: { __typename?: "PaginationMeta"; totalCount: number } | null
    } | null
  } | null> | null
}

export type IsEmailTakenQueryVariables = Exact<{
  email: Scalars["String"]["input"]
}>

export type IsEmailTakenQuery = {
  __typename?: "RootQueryType"
  isEmailTaken?: boolean | null
}

export type MyAvatarQueryVariables = Exact<{ [key: string]: never }>

export type MyAvatarQuery = {
  __typename?: "RootQueryType"
  me?: {
    __typename?: "User"
    id?: string | null
    avatarId?: string | null
  } | null
}

export type MyBasicInfoQueryVariables = Exact<{ [key: string]: never }>

export type MyBasicInfoQuery = {
  __typename?: "RootQueryType"
  me?: {
    __typename?: "User"
    avatarId?: string | null
    id?: string | null
    displayName?: string | null
    username?: string | null
  } | null
}

export type MyUsernameQueryVariables = Exact<{ [key: string]: never }>

export type MyUsernameQuery = {
  __typename?: "RootQueryType"
  me?: {
    __typename?: "User"
    id?: string | null
    username?: string | null
  } | null
}

export type ProfileHeaderByUsernameQueryVariables = Exact<{
  username: Scalars["String"]["input"]
}>

export type ProfileHeaderByUsernameQuery = {
  __typename?: "RootQueryType"
  userByUsername?: {
    __typename: "User"
    id?: string | null
    avatarId?: string | null
    bannerId?: string | null
    displayName?: string | null
    reviews?: {
      __typename?: "PaginatedReviews"
      pagination?: { __typename?: "PaginationMeta"; totalCount: number } | null
    } | null
    libraryBooksByStatus?: {
      __typename?: "PaginatedUserBooks"
      pagination: { __typename?: "PaginationMeta"; totalCount: number }
    } | null
  } | null
}

export type ProfilePageDataByUsernameQueryVariables = Exact<{
  username: Scalars["String"]["input"]
  page?: InputMaybe<Scalars["Int"]["input"]>
  pageSize?: InputMaybe<Scalars["Int"]["input"]>
  sortBy?: InputMaybe<SortBy>
}>

export type ProfilePageDataByUsernameQuery = {
  __typename?: "RootQueryType"
  userByUsername?: {
    __typename: "User"
    id?: string | null
    bio?: string | null
    displayName?: string | null
    insertedAt?: string | null
    ratingsCount?: any | null
    totalRatingsCount?: number | null
    averageRating?: number | null
    username?: string | null
    favoriteAuthors?: Array<{
      __typename: "Author"
      id?: string | null
      slug?: string | null
      name?: string | null
      images?: {
        __typename?: "AuthorImages"
        large?: string | null
        small?: string | null
      } | null
    } | null> | null
    reviews?: {
      __typename?: "PaginatedReviews"
      items?: Array<{
        __typename: "Review"
        commentsCount?: number | null
        content?: any | null
        id?: string | null
        insertedAt?: string | null
        isEdited?: boolean | null
        likesCount?: number | null
        likedByMe?: boolean | null
        user?: {
          __typename?: "User"
          id?: string | null
          username?: string | null
          avatarId?: string | null
        } | null
        book?: {
          __typename?: "Book"
          id?: string | null
          title?: string | null
          slug?: string | null
          myReadingStatus?: {
            __typename?: "ReadingStatus"
            status?: ReadingStatusEnum | null
          } | null
          myShelves: Array<{
            __typename?: "Shelf"
            id: string
            name: string
            slug: string
            booksCount: number
            userId: string
          }>
          images?: {
            __typename?: "BookImages"
            small?: string | null
            medium?: string | null
            large?: string | null
            xl?: string | null
          } | null
        } | null
      } | null> | null
      pagination?: {
        __typename?: "PaginationMeta"
        page: number
        pageSize: number
        totalCount: number
        totalPages: number
      } | null
    } | null
    socialLinks?: {
      __typename?: "SocialLinks"
      instagram?: string | null
      tiktok?: string | null
      twitter?: string | null
      website?: string | null
    } | null
  } | null
  favoriteBooks?: {
    __typename: "User"
    id?: string | null
    favoriteBooks?: Array<{
      __typename?: "Book"
      averageRating?: number | null
      description?: string | null
      id?: string | null
      originalPublicationDate?: string | null
      positionInSeries?: number | null
      ratingsCount?: any | null
      reviewsCount?: number | null
      seriesId?: string | null
      slug?: string | null
      title?: string | null
      myReadingStatus?: {
        __typename?: "ReadingStatus"
        status?: ReadingStatusEnum | null
      } | null
      images?: {
        __typename?: "BookImages"
        large?: string | null
        medium?: string | null
        small?: string | null
        xl?: string | null
      } | null
    } | null> | null
  } | null
  popularReviews?: {
    __typename: "User"
    id?: string | null
    username?: string | null
    reviews?: {
      __typename?: "PaginatedReviews"
      items?: Array<{
        __typename: "Review"
        commentsCount?: number | null
        content?: any | null
        id?: string | null
        insertedAt?: string | null
        isEdited?: boolean | null
        likesCount?: number | null
        likedByMe?: boolean | null
        user?: {
          __typename?: "User"
          id?: string | null
          username?: string | null
          avatarId?: string | null
        } | null
        book?: {
          __typename?: "Book"
          id?: string | null
          title?: string | null
          slug?: string | null
          myReadingStatus?: {
            __typename?: "ReadingStatus"
            status?: ReadingStatusEnum | null
          } | null
          myShelves: Array<{
            __typename?: "Shelf"
            id: string
            name: string
            slug: string
            booksCount: number
            userId: string
          }>
          images?: {
            __typename?: "BookImages"
            small?: string | null
            medium?: string | null
            large?: string | null
            xl?: string | null
          } | null
        } | null
      } | null> | null
      pagination?: {
        __typename?: "PaginationMeta"
        page: number
        pageSize: number
        totalCount: number
        totalPages: number
      } | null
    } | null
  } | null
  recentReviews?: {
    __typename: "User"
    id?: string | null
    username?: string | null
    reviews?: {
      __typename?: "PaginatedReviews"
      items?: Array<{
        __typename: "Review"
        commentsCount?: number | null
        content?: any | null
        id?: string | null
        insertedAt?: string | null
        isEdited?: boolean | null
        likesCount?: number | null
        likedByMe?: boolean | null
        user?: {
          __typename?: "User"
          id?: string | null
          username?: string | null
          avatarId?: string | null
        } | null
        book?: {
          __typename?: "Book"
          id?: string | null
          title?: string | null
          slug?: string | null
          myReadingStatus?: {
            __typename?: "ReadingStatus"
            status?: ReadingStatusEnum | null
          } | null
          myShelves: Array<{
            __typename?: "Shelf"
            id: string
            name: string
            slug: string
            booksCount: number
            userId: string
          }>
          images?: {
            __typename?: "BookImages"
            small?: string | null
            medium?: string | null
            large?: string | null
            xl?: string | null
          } | null
        } | null
      } | null> | null
      pagination?: {
        __typename?: "PaginationMeta"
        page: number
        pageSize: number
        totalCount: number
        totalPages: number
      } | null
    } | null
  } | null
  currentlyReading?: {
    __typename: "User"
    id?: string | null
    libraryBooksByStatus?: {
      __typename?: "PaginatedUserBooks"
      items: Array<{
        __typename?: "UserBook"
        book: {
          __typename?: "Book"
          title?: string | null
          slug?: string | null
          id?: string | null
          images?: {
            __typename?: "BookImages"
            large?: string | null
            medium?: string | null
            small?: string | null
            xl?: string | null
          } | null
        }
      }>
    } | null
  } | null
  read?: {
    __typename: "User"
    id?: string | null
    libraryBooksByStatus?: {
      __typename?: "PaginatedUserBooks"
      items: Array<{
        __typename?: "UserBook"
        book: {
          __typename?: "Book"
          title?: string | null
          slug?: string | null
          id?: string | null
          images?: {
            __typename?: "BookImages"
            large?: string | null
            medium?: string | null
            small?: string | null
            xl?: string | null
          } | null
        }
      }>
    } | null
  } | null
  wantToRead?: {
    __typename: "User"
    id?: string | null
    libraryBooksByStatus?: {
      __typename?: "PaginatedUserBooks"
      items: Array<{
        __typename?: "UserBook"
        book: {
          __typename?: "Book"
          title?: string | null
          slug?: string | null
          id?: string | null
          images?: {
            __typename?: "BookImages"
            large?: string | null
            medium?: string | null
            small?: string | null
            xl?: string | null
          } | null
        }
      }>
    } | null
  } | null
  priority?: {
    __typename: "User"
    id?: string | null
    libraryBooksByStatus?: {
      __typename?: "PaginatedUserBooks"
      items: Array<{
        __typename?: "UserBook"
        book: {
          __typename?: "Book"
          title?: string | null
          slug?: string | null
          id?: string | null
          images?: {
            __typename?: "BookImages"
            large?: string | null
            medium?: string | null
            small?: string | null
            xl?: string | null
          } | null
        }
      }>
    } | null
  } | null
  onHold?: {
    __typename: "User"
    id?: string | null
    libraryBooksByStatus?: {
      __typename?: "PaginatedUserBooks"
      items: Array<{
        __typename?: "UserBook"
        book: {
          __typename?: "Book"
          title?: string | null
          slug?: string | null
          id?: string | null
          images?: {
            __typename?: "BookImages"
            large?: string | null
            medium?: string | null
            small?: string | null
            xl?: string | null
          } | null
        }
      }>
    } | null
  } | null
}

export type UserAvatarQueryVariables = Exact<{
  username: Scalars["String"]["input"]
}>

export type UserAvatarQuery = {
  __typename?: "RootQueryType"
  userByUsername?: {
    __typename?: "User"
    id?: string | null
    avatarId?: string | null
  } | null
}

export type UserIdByUsernameQueryVariables = Exact<{
  username: Scalars["String"]["input"]
}>

export type UserIdByUsernameQuery = {
  __typename?: "RootQueryType"
  userByUsername?: { __typename?: "User"; id?: string | null } | null
}

export type MeQueryVariables = Exact<{ [key: string]: never }>

export type MeQuery = {
  __typename?: "RootQueryType"
  me?: {
    __typename?: "User"
    avatarId?: string | null
    bannerId?: string | null
    bio?: string | null
    displayName?: string | null
    id?: string | null
    ratingsCount?: any | null
    username?: string | null
    socialLinks?: {
      __typename?: "SocialLinks"
      instagram?: string | null
      website?: string | null
      tiktok?: string | null
      twitter?: string | null
    } | null
    favoriteAuthors?: Array<{
      __typename: "Author"
      id?: string | null
      name?: string | null
      slug?: string | null
    } | null> | null
    favoriteBooks?: Array<{
      __typename: "Book"
      id?: string | null
      slug?: string | null
      title?: string | null
      images?: {
        __typename?: "BookImages"
        large?: string | null
        medium?: string | null
        small?: string | null
        xl?: string | null
      } | null
    } | null> | null
  } | null
}

export type UsernameByUserIdQueryVariables = Exact<{
  id: Scalars["ID"]["input"]
}>

export type UsernameByUserIdQuery = {
  __typename?: "RootQueryType"
  user?: {
    __typename: "User"
    id?: string | null
    username?: string | null
  } | null
}

export const FavAuthorFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "FavAuthorFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Author" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "__typename" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "images" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "large" } },
                { kind: "Field", name: { kind: "Name", value: "small" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FavAuthorFieldsFragment, unknown>
export const BookBasicInfoFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "BookBasicInfoFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Book" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "__typename" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "authors" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "images" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "large" } },
                { kind: "Field", name: { kind: "Name", value: "medium" } },
                { kind: "Field", name: { kind: "Name", value: "small" } },
                { kind: "Field", name: { kind: "Name", value: "xl" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BookBasicInfoFieldsFragment, unknown>
export const BookReviewCardFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "BookReviewCardFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Book" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "__typename" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "images" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "large" } },
                { kind: "Field", name: { kind: "Name", value: "medium" } },
                { kind: "Field", name: { kind: "Name", value: "small" } },
                { kind: "Field", name: { kind: "Name", value: "xl" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "myReadingStatus" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "status" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "reviews" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "items" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "commentsCount" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BookReviewCardFieldsFragment, unknown>
export const FavBooksFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "FavBooksFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Book" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "averageRating" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "myReadingStatus" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "status" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "originalPublicationDate" },
          },
          { kind: "Field", name: { kind: "Name", value: "positionInSeries" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "images" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "large" } },
                { kind: "Field", name: { kind: "Name", value: "medium" } },
                { kind: "Field", name: { kind: "Name", value: "small" } },
                { kind: "Field", name: { kind: "Name", value: "xl" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "ratingsCount" } },
          { kind: "Field", name: { kind: "Name", value: "reviewsCount" } },
          { kind: "Field", name: { kind: "Name", value: "seriesId" } },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FavBooksFieldsFragment, unknown>
export const PaginationMetaFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "PaginationMetaFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "PaginationMeta" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "page" } },
          { kind: "Field", name: { kind: "Name", value: "pageSize" } },
          { kind: "Field", name: { kind: "Name", value: "totalCount" } },
          { kind: "Field", name: { kind: "Name", value: "totalPages" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PaginationMetaFieldsFragment, unknown>
export const ReviewCommentFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ReviewCommentFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "ReviewComment" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "__typename" } },
          { kind: "Field", name: { kind: "Name", value: "content" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "insertedAt" } },
          { kind: "Field", name: { kind: "Name", value: "isEdited" } },
          { kind: "Field", name: { kind: "Name", value: "likedByMe" } },
          { kind: "Field", name: { kind: "Name", value: "likesCount" } },
          { kind: "Field", name: { kind: "Name", value: "reviewId" } },
          { kind: "Field", name: { kind: "Name", value: "insertedAt" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "user" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "username" } },
                { kind: "Field", name: { kind: "Name", value: "avatarId" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ReviewCommentFieldsFragment, unknown>
export const ReviewFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ReviewFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Review" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "__typename" } },
          { kind: "Field", name: { kind: "Name", value: "commentsCount" } },
          { kind: "Field", name: { kind: "Name", value: "content" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "insertedAt" } },
          { kind: "Field", name: { kind: "Name", value: "isEdited" } },
          { kind: "Field", name: { kind: "Name", value: "likesCount" } },
          { kind: "Field", name: { kind: "Name", value: "likedByMe" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "user" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "username" } },
                { kind: "Field", name: { kind: "Name", value: "avatarId" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "insertedAt" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "book" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "myReadingStatus" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "status" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "myShelves" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "booksCount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "userId" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "images" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "small" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "medium" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "large" } },
                      { kind: "Field", name: { kind: "Name", value: "xl" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ReviewFieldsFragment, unknown>
export const ShelfFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ShelfFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Shelf" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "userId" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "booksCount" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ShelfFieldsFragment, unknown>
export const UserBookFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "UserBookFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "UserBook" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "rating" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "rating" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "review" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "__typename" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "readingStatus" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "status" } },
                { kind: "Field", name: { kind: "Name", value: "dateStarted" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "dateFinished" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "book" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "authors" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "totalRatingsCount" },
                },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "images" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "large" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "medium" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "small" } },
                      { kind: "Field", name: { kind: "Name", value: "xl" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shelves" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "ShelfFields" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ShelfFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Shelf" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "userId" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "booksCount" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserBookFieldsFragment, unknown>
export const PaginatedUserBookFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "PaginatedUserBookFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "PaginatedUserBooks" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "items" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "UserBookFields" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pagination" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "page" } },
                { kind: "Field", name: { kind: "Name", value: "pageSize" } },
                { kind: "Field", name: { kind: "Name", value: "totalCount" } },
                { kind: "Field", name: { kind: "Name", value: "totalPages" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ShelfFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Shelf" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "userId" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "booksCount" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "UserBookFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "UserBook" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "rating" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "rating" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "review" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "__typename" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "readingStatus" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "status" } },
                { kind: "Field", name: { kind: "Name", value: "dateStarted" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "dateFinished" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "book" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "authors" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "totalRatingsCount" },
                },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "images" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "large" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "medium" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "small" } },
                      { kind: "Field", name: { kind: "Name", value: "xl" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shelves" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "ShelfFields" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PaginatedUserBookFieldsFragment, unknown>
export const UserFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "UserFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "User" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "__typename" } },
          { kind: "Field", name: { kind: "Name", value: "bio" } },
          { kind: "Field", name: { kind: "Name", value: "displayName" } },
          { kind: "Field", name: { kind: "Name", value: "insertedAt" } },
          { kind: "Field", name: { kind: "Name", value: "ratingsCount" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "socialLinks" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "instagram" } },
                { kind: "Field", name: { kind: "Name", value: "tiktok" } },
                { kind: "Field", name: { kind: "Name", value: "twitter" } },
                { kind: "Field", name: { kind: "Name", value: "website" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "totalRatingsCount" } },
          { kind: "Field", name: { kind: "Name", value: "averageRating" } },
          { kind: "Field", name: { kind: "Name", value: "username" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserFieldsFragment, unknown>
export const CreateUserDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CreateUser" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "RegisterUserInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createUser" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateUserMutation, CreateUserMutationVariables>
export const DeleteAccountDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "DeleteAccount" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "deleteAccount" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteAccountMutation,
  DeleteAccountMutationVariables
>
export const ResetLibraryDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "ResetLibrary" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "resetLibrary" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ResetLibraryMutation,
  ResetLibraryMutationVariables
>
export const CreateRatingDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CreateRating" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "bookId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "rating" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createRating" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "bookId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "bookId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "rating" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "rating" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                { kind: "Field", name: { kind: "Name", value: "bookId" } },
                { kind: "Field", name: { kind: "Name", value: "rating" } },
                { kind: "Field", name: { kind: "Name", value: "userId" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateRatingMutation,
  CreateRatingMutationVariables
>
export const CreateReviewDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CreateReview" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "bookId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "content" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Json" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createReview" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "bookId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "bookId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "content" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "content" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "book" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "commentsCount" },
                },
                { kind: "Field", name: { kind: "Name", value: "content" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "isEdited" } },
                { kind: "Field", name: { kind: "Name", value: "insertedAt" } },
                { kind: "Field", name: { kind: "Name", value: "likesCount" } },
                { kind: "Field", name: { kind: "Name", value: "likedByMe" } },
                { kind: "Field", name: { kind: "Name", value: "insertedAt" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "user" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateReviewMutation,
  CreateReviewMutationVariables
>
export const CreateReviewCommentDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CreateReviewComment" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "content" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "reviewId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createReviewComment" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "content" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "content" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "reviewId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "reviewId" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "content" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "insertedAt" } },
                { kind: "Field", name: { kind: "Name", value: "likesCount" } },
                { kind: "Field", name: { kind: "Name", value: "likedByMe" } },
                { kind: "Field", name: { kind: "Name", value: "isEdited" } },
                { kind: "Field", name: { kind: "Name", value: "reviewId" } },
                { kind: "Field", name: { kind: "Name", value: "insertedAt" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "user" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "username" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "avatarId" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateReviewCommentMutation,
  CreateReviewCommentMutationVariables
>
export const DeleteReviewDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "DeleteReview" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "deleteReview" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "id" },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteReviewMutation,
  DeleteReviewMutationVariables
>
export const DeleteReviewCommentDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "DeleteReviewComment" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "deleteReviewComment" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "id" },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteReviewCommentMutation,
  DeleteReviewCommentMutationVariables
>
export const LikeReviewDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "LikeReview" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "reviewId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "likeReview" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "reviewId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "reviewId" },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LikeReviewMutation, LikeReviewMutationVariables>
export const LikeReviewCommentDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "LikeReviewComment" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "reviewCommentId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "likeReviewComment" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "reviewCommentId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "reviewCommentId" },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  LikeReviewCommentMutation,
  LikeReviewCommentMutationVariables
>
export const UnlikeReviewDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UnlikeReview" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "reviewId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "unlikeReview" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "reviewId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "reviewId" },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UnlikeReviewMutation,
  UnlikeReviewMutationVariables
>
export const UnlikeReviewCommentDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UnlikeReviewComment" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "reviewCommentId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "unlikeReviewComment" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "reviewCommentId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "reviewCommentId" },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UnlikeReviewCommentMutation,
  UnlikeReviewCommentMutationVariables
>
export const UpdateRatingDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateRating" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "bookId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "rating" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateRating" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "bookId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "bookId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "rating" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "rating" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                { kind: "Field", name: { kind: "Name", value: "bookId" } },
                { kind: "Field", name: { kind: "Name", value: "rating" } },
                { kind: "Field", name: { kind: "Name", value: "userId" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateRatingMutation,
  UpdateRatingMutationVariables
>
export const UpdateReviewDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateReview" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "content" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Json" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateReview" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "content" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "content" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "id" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "book" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "commentsCount" },
                },
                { kind: "Field", name: { kind: "Name", value: "content" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "isEdited" } },
                { kind: "Field", name: { kind: "Name", value: "insertedAt" } },
                { kind: "Field", name: { kind: "Name", value: "likesCount" } },
                { kind: "Field", name: { kind: "Name", value: "likedByMe" } },
                { kind: "Field", name: { kind: "Name", value: "insertedAt" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "user" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateReviewMutation,
  UpdateReviewMutationVariables
>
export const UpdateReviewCommentDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateReviewComment" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "content" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateReviewComment" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "id" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "content" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "content" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "content" } },
                { kind: "Field", name: { kind: "Name", value: "insertedAt" } },
                { kind: "Field", name: { kind: "Name", value: "isEdited" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateReviewCommentMutation,
  UpdateReviewCommentMutationVariables
>
export const AddBooksToShelfDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "AddBooksToShelf" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "bookIds" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "ListType",
              type: {
                kind: "NonNullType",
                type: {
                  kind: "NamedType",
                  name: { kind: "Name", value: "ID" },
                },
              },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "shelfIds" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "ListType",
              type: {
                kind: "NonNullType",
                type: {
                  kind: "NamedType",
                  name: { kind: "Name", value: "ID" },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "addBooksToShelves" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "bookIds" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "bookIds" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "shelfIds" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "shelfIds" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "booksAffected" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shelvesAffected" },
                },
                { kind: "Field", name: { kind: "Name", value: "success" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddBooksToShelfMutation,
  AddBooksToShelfMutationVariables
>
export const CreateShelfDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CreateShelf" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "name" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createShelf" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "name" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "name" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "userId" } },
                { kind: "Field", name: { kind: "Name", value: "booksCount" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateShelfMutation, CreateShelfMutationVariables>
export const DeleteReadingStatusesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "DeleteReadingStatuses" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "bookIds" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "ListType",
              type: {
                kind: "NonNullType",
                type: {
                  kind: "NamedType",
                  name: { kind: "Name", value: "ID" },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "deleteReadingStatuses" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "bookIds" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "bookIds" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "successfulBooks" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "totalBooksProcessed" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteReadingStatusesMutation,
  DeleteReadingStatusesMutationVariables
>
export const DeleteShelfDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "DeleteShelf" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "deleteShelf" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "id" },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteShelfMutation, DeleteShelfMutationVariables>
export const RemoveBooksFromShelfDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "RemoveBooksFromShelf" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "bookIds" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "ListType",
              type: {
                kind: "NonNullType",
                type: {
                  kind: "NamedType",
                  name: { kind: "Name", value: "ID" },
                },
              },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "shelfIds" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "ListType",
              type: {
                kind: "NonNullType",
                type: {
                  kind: "NamedType",
                  name: { kind: "Name", value: "ID" },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "removeBooksFromShelves" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "bookIds" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "bookIds" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "shelfIds" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "shelfIds" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "booksAffected" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shelvesAffected" },
                },
                { kind: "Field", name: { kind: "Name", value: "success" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RemoveBooksFromShelfMutation,
  RemoveBooksFromShelfMutationVariables
>
export const RenameShelfDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "RenameShelf" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "newName" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "renameShelf" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "id" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "newName" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "newName" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "userId" } },
                { kind: "Field", name: { kind: "Name", value: "booksCount" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RenameShelfMutation, RenameShelfMutationVariables>
export const SetReadingStatusesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "SetReadingStatuses" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "bookIds" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "ListType",
              type: {
                kind: "NonNullType",
                type: {
                  kind: "NamedType",
                  name: { kind: "Name", value: "ID" },
                },
              },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "status" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "ReadingStatusEnum" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "dateStarted" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Date" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "dateFinished" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Date" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "setReadingStatuses" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "bookIds" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "bookIds" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "status" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "status" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "dateStarted" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "dateStarted" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "dateFinished" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "dateFinished" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "successfulBooks" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "totalBooksProcessed" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SetReadingStatusesMutation,
  SetReadingStatusesMutationVariables
>
export const GenerateUploadUrlDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "GenerateUploadUrl" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "generateUploadUrl" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "uploadId" } },
                { kind: "Field", name: { kind: "Name", value: "uploadUrl" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GenerateUploadUrlMutation,
  GenerateUploadUrlMutationVariables
>
export const ProcessCsvUploadDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "processCsvUpload" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "source" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "UploadSource" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "uploadId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "processCsvUpload" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "source" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "source" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "uploadId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "uploadId" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "status" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "counts" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "successfulBooks" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "ratings" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "readingStatuses" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "reviews" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shelves" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "totalBooks" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "errors" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "error" } },
                      { kind: "Field", name: { kind: "Name", value: "line" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ProcessCsvUploadMutation,
  ProcessCsvUploadMutationVariables
>
export const ProcessImageUploadDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "ProcessImageUpload" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "imageType" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "ImageFileType" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "uploadId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "processImageUpload" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "imageType" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "imageType" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "uploadId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "uploadId" },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ProcessImageUploadMutation,
  ProcessImageUploadMutationVariables
>
export const UpdateUserDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateUser" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "UpdateUserInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateUser" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "bio" } },
                { kind: "Field", name: { kind: "Name", value: "displayName" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "username" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "socialLinks" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "instagram" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "tiktok" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "twitter" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "website" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "favoriteAuthors" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "favoriteBooks" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "images" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "large" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "medium" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "small" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "xl" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateUserMutation, UpdateUserMutationVariables>
export const AuthorBySlugDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "AuthorBySlug" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "slug" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "pageSize" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "page" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "authorBySlug" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "slug" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "slug" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "placeOfBirth" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "images" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "large" } },
                      { kind: "Field", name: { kind: "Name", value: "small" } },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "bio" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "books" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "page" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "page" },
                      },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "pageSize" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "pageSize" },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "pagination" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "page" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "pageSize" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "totalCount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "totalPages" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "items" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "myReadingStatus" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "status" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "title" },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "totalRatingsCount",
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "averageRating" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "reviewsCount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "slug" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "myShelves" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "slug" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "userId" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "booksCount" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "images" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "large" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "medium" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "small" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "xl" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "authors" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "slug" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "series" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "page" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "page" },
                      },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "pageSize" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "pageSize" },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "pagination" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "page" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "pageSize" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "totalCount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "totalPages" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "items" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "description" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "slug" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "books" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "items" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "slug" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "title",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "images",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "large",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "medium",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "small",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "xl",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AuthorBySlugQuery, AuthorBySlugQueryVariables>
export const AuthorMetadataDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "AuthorMetadata" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "slug" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "authorBySlug" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "slug" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "slug" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "images" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "small" } },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "bio" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AuthorMetadataQuery, AuthorMetadataQueryVariables>
export const MyFavoriteAuthorsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "MyFavoriteAuthors" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "me" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "favoriteAuthors" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  MyFavoriteAuthorsQuery,
  MyFavoriteAuthorsQueryVariables
>
export const SearchAuthorsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "SearchAuthors" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "name" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "searchAuthors" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "name" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "name" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "images" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "small" } },
                      { kind: "Field", name: { kind: "Name", value: "large" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SearchAuthorsQuery, SearchAuthorsQueryVariables>
export const BookByIdDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "BookById" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "book" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "id" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "BookReviewCardFields" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "BookReviewCardFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Book" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "__typename" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "images" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "large" } },
                { kind: "Field", name: { kind: "Name", value: "medium" } },
                { kind: "Field", name: { kind: "Name", value: "small" } },
                { kind: "Field", name: { kind: "Name", value: "xl" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "myReadingStatus" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "status" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "reviews" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "items" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "commentsCount" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BookByIdQuery, BookByIdQueryVariables>
export const BookBySlugDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "BookBySlug" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "slug" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "bookBySlug" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "slug" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "slug" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "averageRating" },
                },
                { kind: "Field", name: { kind: "Name", value: "description" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "totalRatingsCount" },
                },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "numPages" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "tags" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "authors" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "originalPublicationDate" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "positionInSeries" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "images" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "large" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "medium" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "small" } },
                      { kind: "Field", name: { kind: "Name", value: "xl" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "myShelves" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "booksCount" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "userId" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "ratingsCount" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "reviewsCount" },
                },
                { kind: "Field", name: { kind: "Name", value: "seriesId" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "myReadingStatus" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "status" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "myRating" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rating" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "myReview" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "similarBooks" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "images" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "large" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "medium" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "small" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "xl" },
                            },
                          ],
                        },
                      },
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BookBySlugQuery, BookBySlugQueryVariables>
export const BookInfoBasicBySlugDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "BookInfoBasicBySlug" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "slug" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "bookBySlug" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "slug" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "slug" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "BookBasicInfoFields" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "BookBasicInfoFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Book" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "__typename" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "authors" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "images" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "large" } },
                { kind: "Field", name: { kind: "Name", value: "medium" } },
                { kind: "Field", name: { kind: "Name", value: "small" } },
                { kind: "Field", name: { kind: "Name", value: "xl" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  BookInfoBasicBySlugQuery,
  BookInfoBasicBySlugQueryVariables
>
export const BookMetadataDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "BookMetadata" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "slug" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "bookBySlug" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "slug" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "slug" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                { kind: "Field", name: { kind: "Name", value: "description" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "authors" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BookMetadataQuery, BookMetadataQueryVariables>
export const BrowseBooksDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "BrowseBooks" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "filters" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "BookFilters" },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "page" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "pageSize" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "sortBy" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "BookSortOptions" },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "browseBooks" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "filters" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "filters" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "page" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "page" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "pageSize" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "pageSize" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "sortBy" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "sortBy" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "pagination" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "page" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "pageSize" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "totalPages" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "totalCount" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "items" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "numPages" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "images" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "large" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "medium" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "small" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "xl" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "authors" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "slug" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "totalRatingsCount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "averageRating" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "originalPublicationDate",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "myReadingStatus" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "status" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BrowseBooksQuery, BrowseBooksQueryVariables>
export const FavoriteBooksByUsernameDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "FavoriteBooksByUsername" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "username" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "userByUsername" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "username" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "username" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "favoriteBooks" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "FavBooksFields" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "FavBooksFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Book" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "averageRating" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "myReadingStatus" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "status" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "originalPublicationDate" },
          },
          { kind: "Field", name: { kind: "Name", value: "positionInSeries" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "images" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "large" } },
                { kind: "Field", name: { kind: "Name", value: "medium" } },
                { kind: "Field", name: { kind: "Name", value: "small" } },
                { kind: "Field", name: { kind: "Name", value: "xl" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "ratingsCount" } },
          { kind: "Field", name: { kind: "Name", value: "reviewsCount" } },
          { kind: "Field", name: { kind: "Name", value: "seriesId" } },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  FavoriteBooksByUsernameQuery,
  FavoriteBooksByUsernameQueryVariables
>
export const HomeDataDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "HomeData" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "recentReviews" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "user" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "username" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "book" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "images" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "large" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "medium" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "small" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "xl" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "trendingBooksWeekly" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "images" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "large" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "medium" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "small" } },
                      { kind: "Field", name: { kind: "Name", value: "xl" } },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "authors" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "browseBooks" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "filters" },
                value: { kind: "NullValue" },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "sortBy" },
                value: { kind: "EnumValue", value: "AVERAGE_RATING_DESC" },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "page" },
                value: { kind: "IntValue", value: "1" },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "pageSize" },
                value: { kind: "IntValue", value: "9" },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "items" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "images" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "large" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "medium" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "small" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "xl" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<HomeDataQuery, HomeDataQueryVariables>
export const MyFavoriteBooksDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "MyFavoriteBooks" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "me" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "favoriteBooks" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "images" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "large" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "medium" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "small" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "xl" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  MyFavoriteBooksQuery,
  MyFavoriteBooksQueryVariables
>
export const SearchBooksByTitleDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "SearchBooksByTitle" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "title" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "searchBooks" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "title" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "title" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                { kind: "Field", name: { kind: "Name", value: "authors" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "images" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "xl" } },
                      { kind: "Field", name: { kind: "Name", value: "large" } },
                      { kind: "Field", name: { kind: "Name", value: "small" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "medium" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SearchBooksByTitleQuery,
  SearchBooksByTitleQueryVariables
>
export const TopRatedBooksDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "TopRatedBooks" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "page" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "pageSize" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "browseBooks" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "filters" },
                value: { kind: "NullValue" },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "sortBy" },
                value: { kind: "EnumValue", value: "AVERAGE_RATING_DESC" },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "page" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "page" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "pageSize" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "pageSize" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "items" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "averageRating" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "description" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "originalPublicationDate",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "positionInSeries" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "images" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "large" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "medium" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "small" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "xl" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "ratingsCount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "totalRatingsCount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "reviewsCount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "seriesId" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "myReadingStatus" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "status" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "myShelves" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "booksCount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "slug" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "userId" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "authors" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "slug" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "pagination" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "page" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "pageSize" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "totalCount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "totalPages" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TopRatedBooksQuery, TopRatedBooksQueryVariables>
export const SingleReviewMetadataDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "SingleReviewMetadata" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "bookSlug" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "username" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "userByUsername" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "username" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "username" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "username" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "reviewForBook" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "bookSlug" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "bookSlug" },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "book" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "title" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SingleReviewMetadataQuery,
  SingleReviewMetadataQueryVariables
>
export const SingleReviewPageDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "SingleReviewPage" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "bookSlug" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "username" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "userByUsername" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "username" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "username" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "avatarId" } },
                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "reviewForBook" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "bookSlug" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "bookSlug" },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "ReviewFields" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "book" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "authors" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "slug" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ReviewFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Review" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "__typename" } },
          { kind: "Field", name: { kind: "Name", value: "commentsCount" } },
          { kind: "Field", name: { kind: "Name", value: "content" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "insertedAt" } },
          { kind: "Field", name: { kind: "Name", value: "isEdited" } },
          { kind: "Field", name: { kind: "Name", value: "likesCount" } },
          { kind: "Field", name: { kind: "Name", value: "likedByMe" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "user" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "username" } },
                { kind: "Field", name: { kind: "Name", value: "avatarId" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "insertedAt" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "book" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "myReadingStatus" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "status" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "myShelves" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "booksCount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "userId" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "images" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "small" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "medium" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "large" } },
                      { kind: "Field", name: { kind: "Name", value: "xl" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SingleReviewPageQuery,
  SingleReviewPageQueryVariables
>
export const BookReviewsBySlugDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "BookReviewsBySlug" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "slug" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "page" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "pageSize" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "sortBy" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "SortBy" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "bookBySlug" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "slug" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "slug" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "averageRating" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "totalRatingsCount" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "reviews" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "page" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "page" },
                      },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "pageSize" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "pageSize" },
                      },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "sortBy" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "sortBy" },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "items" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "FragmentSpread",
                              name: { kind: "Name", value: "ReviewFields" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "comments" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "items" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "FragmentSpread",
                                          name: {
                                            kind: "Name",
                                            value: "ReviewCommentFields",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "pagination" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "page" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "pageSize" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "totalCount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "totalPages" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ReviewFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Review" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "__typename" } },
          { kind: "Field", name: { kind: "Name", value: "commentsCount" } },
          { kind: "Field", name: { kind: "Name", value: "content" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "insertedAt" } },
          { kind: "Field", name: { kind: "Name", value: "isEdited" } },
          { kind: "Field", name: { kind: "Name", value: "likesCount" } },
          { kind: "Field", name: { kind: "Name", value: "likedByMe" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "user" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "username" } },
                { kind: "Field", name: { kind: "Name", value: "avatarId" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "insertedAt" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "book" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "myReadingStatus" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "status" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "myShelves" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "booksCount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "userId" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "images" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "small" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "medium" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "large" } },
                      { kind: "Field", name: { kind: "Name", value: "xl" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ReviewCommentFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "ReviewComment" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "__typename" } },
          { kind: "Field", name: { kind: "Name", value: "content" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "insertedAt" } },
          { kind: "Field", name: { kind: "Name", value: "isEdited" } },
          { kind: "Field", name: { kind: "Name", value: "likedByMe" } },
          { kind: "Field", name: { kind: "Name", value: "likesCount" } },
          { kind: "Field", name: { kind: "Name", value: "reviewId" } },
          { kind: "Field", name: { kind: "Name", value: "insertedAt" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "user" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "username" } },
                { kind: "Field", name: { kind: "Name", value: "avatarId" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  BookReviewsBySlugQuery,
  BookReviewsBySlugQueryVariables
>
export const MyReviewForBookDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "MyReviewForBook" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "slug" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "bookBySlug" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "slug" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "slug" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "authors" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "images" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "large" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "medium" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "small" } },
                      { kind: "Field", name: { kind: "Name", value: "xl" } },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "myReadingStatus" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "status" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "myRating" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rating" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "myShelves" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "userId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "booksCount" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "similarBooks" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "myReview" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "commentsCount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "content" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "insertedAt" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "isEdited" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "likesCount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "likedByMe" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "user" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "insertedAt" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "book" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "title" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "slug" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  MyReviewForBookQuery,
  MyReviewForBookQueryVariables
>
export const GetReviewDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetReview" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "review" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "id" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "book" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "commentsCount" },
                },
                { kind: "Field", name: { kind: "Name", value: "content" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "insertedAt" } },
                { kind: "Field", name: { kind: "Name", value: "isEdited" } },
                { kind: "Field", name: { kind: "Name", value: "likedByMe" } },
                { kind: "Field", name: { kind: "Name", value: "likesCount" } },
                { kind: "Field", name: { kind: "Name", value: "insertedAt" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "user" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "comments" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "items" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "content" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "insertedAt" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "isEdited" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "likedByMe" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "likesCount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "reviewId" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "insertedAt" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "user" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetReviewQuery, GetReviewQueryVariables>
export const ReviewCommentsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "ReviewComments" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "page" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "pageSize" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "sortBy" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "SortBy" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "review" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "id" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "comments" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "page" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "page" },
                      },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "pageSize" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "pageSize" },
                      },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "sortBy" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "sortBy" },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "items" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "FragmentSpread",
                              name: {
                                kind: "Name",
                                value: "ReviewCommentFields",
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "pagination" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "page" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "pageSize" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "totalCount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "totalPages" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "commentsCount" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ReviewCommentFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "ReviewComment" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "__typename" } },
          { kind: "Field", name: { kind: "Name", value: "content" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "insertedAt" } },
          { kind: "Field", name: { kind: "Name", value: "isEdited" } },
          { kind: "Field", name: { kind: "Name", value: "likedByMe" } },
          { kind: "Field", name: { kind: "Name", value: "likesCount" } },
          { kind: "Field", name: { kind: "Name", value: "reviewId" } },
          { kind: "Field", name: { kind: "Name", value: "insertedAt" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "user" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "username" } },
                { kind: "Field", name: { kind: "Name", value: "avatarId" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ReviewCommentsQuery, ReviewCommentsQueryVariables>
export const ReviewCommentsCountDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "ReviewCommentsCount" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "review" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "id" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "commentsCount" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ReviewCommentsCountQuery,
  ReviewCommentsCountQueryVariables
>
export const TrendingReviewsWeeklyDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "TrendingReviewsWeekly" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "trendingReviews" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "ReviewFields" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ReviewFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Review" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "__typename" } },
          { kind: "Field", name: { kind: "Name", value: "commentsCount" } },
          { kind: "Field", name: { kind: "Name", value: "content" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "insertedAt" } },
          { kind: "Field", name: { kind: "Name", value: "isEdited" } },
          { kind: "Field", name: { kind: "Name", value: "likesCount" } },
          { kind: "Field", name: { kind: "Name", value: "likedByMe" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "user" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "username" } },
                { kind: "Field", name: { kind: "Name", value: "avatarId" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "insertedAt" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "book" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "myReadingStatus" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "status" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "myShelves" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "booksCount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "userId" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "images" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "small" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "medium" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "large" } },
                      { kind: "Field", name: { kind: "Name", value: "xl" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  TrendingReviewsWeeklyQuery,
  TrendingReviewsWeeklyQueryVariables
>
export const UserRatingForBookDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "UserRatingForBook" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "bookId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "userId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "user" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "userId" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "ratingForBook" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "bookId" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "bookId" },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rating" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "userId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "bookId" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UserRatingForBookQuery,
  UserRatingForBookQueryVariables
>
export const UserReviewForBookDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "UserReviewForBook" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "bookSlug" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "username" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "userByUsername" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "username" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "username" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "reviewForBook" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "bookSlug" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "bookSlug" },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "ReviewFields" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ReviewFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Review" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "__typename" } },
          { kind: "Field", name: { kind: "Name", value: "commentsCount" } },
          { kind: "Field", name: { kind: "Name", value: "content" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "insertedAt" } },
          { kind: "Field", name: { kind: "Name", value: "isEdited" } },
          { kind: "Field", name: { kind: "Name", value: "likesCount" } },
          { kind: "Field", name: { kind: "Name", value: "likedByMe" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "user" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "username" } },
                { kind: "Field", name: { kind: "Name", value: "avatarId" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "insertedAt" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "book" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "myReadingStatus" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "status" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "myShelves" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "booksCount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "userId" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "images" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "small" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "medium" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "large" } },
                      { kind: "Field", name: { kind: "Name", value: "xl" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UserReviewForBookQuery,
  UserReviewForBookQueryVariables
>
export const UserReviewsSortByDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "UserReviewsSortBy" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "username" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "page" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "pageSize" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "sortBy" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "SortBy" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "userByUsername" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "username" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "username" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "username" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "reviews" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "page" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "page" },
                      },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "pageSize" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "pageSize" },
                      },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "sortBy" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "sortBy" },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "items" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "FragmentSpread",
                              name: { kind: "Name", value: "ReviewFields" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "pagination" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "page" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "pageSize" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "totalCount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "totalPages" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ReviewFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Review" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "__typename" } },
          { kind: "Field", name: { kind: "Name", value: "commentsCount" } },
          { kind: "Field", name: { kind: "Name", value: "content" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "insertedAt" } },
          { kind: "Field", name: { kind: "Name", value: "isEdited" } },
          { kind: "Field", name: { kind: "Name", value: "likesCount" } },
          { kind: "Field", name: { kind: "Name", value: "likedByMe" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "user" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "username" } },
                { kind: "Field", name: { kind: "Name", value: "avatarId" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "insertedAt" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "book" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "myReadingStatus" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "status" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "myShelves" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "booksCount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "userId" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "images" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "small" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "medium" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "large" } },
                      { kind: "Field", name: { kind: "Name", value: "xl" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UserReviewsSortByQuery,
  UserReviewsSortByQueryVariables
>
export const SeriesBySlugDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "SeriesBySlug" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "slug" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "page" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "pageSize" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "seriesBySlug" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "slug" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "slug" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                { kind: "Field", name: { kind: "Name", value: "description" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "authors" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "books" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "page" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "page" },
                      },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "pageSize" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "pageSize" },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "items" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "authors" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "slug" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "averageRating" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "positionInSeries" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "images" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "large" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "medium" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "small" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "xl" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "reviewsCount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "seriesId" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "slug" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "title" },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "totalRatingsCount",
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "myReadingStatus" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "status" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "myShelves" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "booksCount" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "slug" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "userId" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "similarBooks" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "pagination" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "page" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "pageSize" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "totalCount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "totalPages" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SeriesBySlugQuery, SeriesBySlugQueryVariables>
export const SeriesMetadataDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "SeriesMetadata" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "slug" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "seriesBySlug" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "slug" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "slug" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                { kind: "Field", name: { kind: "Name", value: "description" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "authors" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SeriesMetadataQuery, SeriesMetadataQueryVariables>
export const SeriesPreviewDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "SeriesPreview" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "series" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "id" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "books" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "page" },
                      value: { kind: "IntValue", value: "1" },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "pageSize" },
                      value: { kind: "IntValue", value: "5" },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "items" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "positionInSeries" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "images" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "large" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "medium" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "small" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "xl" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "slug" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "title" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "authors" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SeriesPreviewQuery, SeriesPreviewQueryVariables>
export const AllBooksByStatusDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "AllBooksByStatus" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "username" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            alias: { kind: "Name", value: "currentlyReading" },
            name: { kind: "Name", value: "userByUsername" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "username" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "username" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "libraryBooksByStatus" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "status" },
                      value: { kind: "EnumValue", value: "CURRENTLY_READING" },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "items" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "book" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "title" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "slug" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "images" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "large",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "medium",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "small",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "xl" },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "read" },
            name: { kind: "Name", value: "userByUsername" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "username" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "username" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "libraryBooksByStatus" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "status" },
                      value: { kind: "EnumValue", value: "READ" },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "items" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "book" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "title" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "slug" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "images" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "large",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "medium",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "small",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "xl" },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "wantToRead" },
            name: { kind: "Name", value: "userByUsername" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "username" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "username" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "libraryBooksByStatus" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "status" },
                      value: { kind: "EnumValue", value: "WANT_TO_READ" },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "items" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "book" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "title" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "slug" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "images" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "large",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "medium",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "small",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "xl" },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "priority" },
            name: { kind: "Name", value: "userByUsername" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "username" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "username" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "libraryBooksByStatus" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "status" },
                      value: { kind: "EnumValue", value: "PRIORITY" },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "items" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "book" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "title" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "slug" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "images" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "large",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "medium",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "small",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "xl" },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "onHold" },
            name: { kind: "Name", value: "userByUsername" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "username" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "username" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "libraryBooksByStatus" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "status" },
                      value: { kind: "EnumValue", value: "ON_HOLD" },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "items" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "book" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "title" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "slug" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "images" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "large",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "medium",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "small",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "xl" },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AllBooksByStatusQuery,
  AllBooksByStatusQueryVariables
>
export const AllBooksInLibraryDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "AllBooksInLibrary" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "username" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "page" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "pageSize" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "userByUsername" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "username" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "username" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "libraryBooks" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "page" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "page" },
                      },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "pageSize" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "pageSize" },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "PaginatedUserBookFields",
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ShelfFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Shelf" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "userId" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "booksCount" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "UserBookFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "UserBook" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "rating" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "rating" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "review" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "__typename" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "readingStatus" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "status" } },
                { kind: "Field", name: { kind: "Name", value: "dateStarted" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "dateFinished" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "book" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "authors" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "totalRatingsCount" },
                },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "images" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "large" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "medium" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "small" } },
                      { kind: "Field", name: { kind: "Name", value: "xl" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shelves" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "ShelfFields" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "PaginatedUserBookFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "PaginatedUserBooks" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "items" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "UserBookFields" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pagination" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "page" } },
                { kind: "Field", name: { kind: "Name", value: "pageSize" } },
                { kind: "Field", name: { kind: "Name", value: "totalCount" } },
                { kind: "Field", name: { kind: "Name", value: "totalPages" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AllBooksInLibraryQuery,
  AllBooksInLibraryQueryVariables
>
export const BooksByCustomShelfSlugDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "BooksByCustomShelfSlug" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "username" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "slug" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "page" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "pageSize" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "userByUsername" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "username" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "username" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shelf" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "slug" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "slug" },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "books" },
                        arguments: [
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "page" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "page" },
                            },
                          },
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "pageSize" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "pageSize" },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "FragmentSpread",
                              name: {
                                kind: "Name",
                                value: "PaginatedUserBookFields",
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ShelfFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Shelf" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "userId" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "booksCount" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "UserBookFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "UserBook" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "rating" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "rating" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "review" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "__typename" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "readingStatus" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "status" } },
                { kind: "Field", name: { kind: "Name", value: "dateStarted" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "dateFinished" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "book" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "authors" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "totalRatingsCount" },
                },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "images" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "large" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "medium" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "small" } },
                      { kind: "Field", name: { kind: "Name", value: "xl" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shelves" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "ShelfFields" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "PaginatedUserBookFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "PaginatedUserBooks" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "items" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "UserBookFields" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pagination" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "page" } },
                { kind: "Field", name: { kind: "Name", value: "pageSize" } },
                { kind: "Field", name: { kind: "Name", value: "totalCount" } },
                { kind: "Field", name: { kind: "Name", value: "totalPages" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  BooksByCustomShelfSlugQuery,
  BooksByCustomShelfSlugQueryVariables
>
export const BooksByStatusDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "BooksByStatus" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "username" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "status" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "ReadingStatusEnum" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "page" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "pageSize" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "userByUsername" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "username" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "username" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "libraryBooksByStatus" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "status" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "status" },
                      },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "page" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "page" },
                      },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "pageSize" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "pageSize" },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "PaginatedUserBookFields",
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ShelfFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Shelf" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "userId" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "booksCount" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "UserBookFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "UserBook" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "rating" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "rating" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "review" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "__typename" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "readingStatus" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "status" } },
                { kind: "Field", name: { kind: "Name", value: "dateStarted" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "dateFinished" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "book" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "authors" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "totalRatingsCount" },
                },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "images" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "large" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "medium" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "small" } },
                      { kind: "Field", name: { kind: "Name", value: "xl" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "shelves" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "ShelfFields" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "PaginatedUserBookFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "PaginatedUserBooks" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "items" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "UserBookFields" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pagination" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "page" } },
                { kind: "Field", name: { kind: "Name", value: "pageSize" } },
                { kind: "Field", name: { kind: "Name", value: "totalCount" } },
                { kind: "Field", name: { kind: "Name", value: "totalPages" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BooksByStatusQuery, BooksByStatusQueryVariables>
export const CustomShelfLabelDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "CustomShelfLabel" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "username" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "slug" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "userByUsername" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "username" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "username" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shelf" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "slug" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "slug" },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CustomShelfLabelQuery,
  CustomShelfLabelQueryVariables
>
export const CustomShelvesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "CustomShelves" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "username" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "userByUsername" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "username" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "username" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                { kind: "Field", name: { kind: "Name", value: "username" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shelves" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "userId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "booksCount" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CustomShelvesQuery, CustomShelvesQueryVariables>
export const CustomShelvesBooksDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "CustomShelvesBooks" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "username" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "userByUsername" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "username" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "username" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                { kind: "Field", name: { kind: "Name", value: "username" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shelves" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "userId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "books" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "items" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "book" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "myRating",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "rating",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "authors",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "name",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "slug",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "title",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "slug" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "images",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "large",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "medium",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "small",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "xl",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "pagination" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "page" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "pageSize" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "totalCount" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "totalPages" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CustomShelvesBooksQuery,
  CustomShelvesBooksQueryVariables
>
export const LibraryMobileCustomShelfCoversDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "LibraryMobileCustomShelfCovers" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "username" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "userByUsername" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "username" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "username" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shelves" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "books" },
                        arguments: [
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "page" },
                            value: { kind: "IntValue", value: "1" },
                          },
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "pageSize" },
                            value: { kind: "IntValue", value: "3" },
                          },
                        ],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "items" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "book" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "images",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "large",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "medium",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "small",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "xl",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  LibraryMobileCustomShelfCoversQuery,
  LibraryMobileCustomShelfCoversQueryVariables
>
export const LibraryMobileShelfCoversByStatusDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "LibraryMobileShelfCoversByStatus" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "username" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "userByUsername" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "username" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "username" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "currentlyReading" },
                  name: { kind: "Name", value: "libraryBooksByStatus" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "page" },
                      value: { kind: "IntValue", value: "1" },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "pageSize" },
                      value: { kind: "IntValue", value: "3" },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "status" },
                      value: { kind: "EnumValue", value: "CURRENTLY_READING" },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "items" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "book" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "images" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "large",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "medium",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "small",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "xl" },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "didNotFinish" },
                  name: { kind: "Name", value: "libraryBooksByStatus" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "page" },
                      value: { kind: "IntValue", value: "1" },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "pageSize" },
                      value: { kind: "IntValue", value: "3" },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "status" },
                      value: { kind: "EnumValue", value: "DID_NOT_FINISH" },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "items" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "book" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "images" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "large",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "medium",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "small",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "xl" },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "onHold" },
                  name: { kind: "Name", value: "libraryBooksByStatus" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "page" },
                      value: { kind: "IntValue", value: "1" },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "pageSize" },
                      value: { kind: "IntValue", value: "3" },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "status" },
                      value: { kind: "EnumValue", value: "ON_HOLD" },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "items" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "book" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "images" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "large",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "medium",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "small",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "xl" },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "priority" },
                  name: { kind: "Name", value: "libraryBooksByStatus" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "page" },
                      value: { kind: "IntValue", value: "1" },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "pageSize" },
                      value: { kind: "IntValue", value: "3" },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "status" },
                      value: { kind: "EnumValue", value: "PRIORITY" },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "items" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "book" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "images" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "large",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "medium",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "small",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "xl" },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "read" },
                  name: { kind: "Name", value: "libraryBooksByStatus" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "page" },
                      value: { kind: "IntValue", value: "1" },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "pageSize" },
                      value: { kind: "IntValue", value: "3" },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "status" },
                      value: { kind: "EnumValue", value: "READ" },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "items" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "book" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "images" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "large",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "medium",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "small",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "xl" },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "wantToRead" },
                  name: { kind: "Name", value: "libraryBooksByStatus" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "page" },
                      value: { kind: "IntValue", value: "1" },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "pageSize" },
                      value: { kind: "IntValue", value: "3" },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "status" },
                      value: { kind: "EnumValue", value: "WANT_TO_READ" },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "items" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "book" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "images" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "large",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "medium",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "small",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "xl" },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "libraryBooks" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "page" },
                      value: { kind: "IntValue", value: "1" },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "pageSize" },
                      value: { kind: "IntValue", value: "3" },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "items" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "book" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "images" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "large",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "medium",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "small",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "xl" },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  LibraryMobileShelfCoversByStatusQuery,
  LibraryMobileShelfCoversByStatusQueryVariables
>
export const MyBooksByStatusDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "MyBooksByStatus" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "status" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "ReadingStatusEnum" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "page" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "pageSize" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "me" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "libraryBooksByStatus" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "page" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "page" },
                      },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "pageSize" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "pageSize" },
                      },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "status" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "status" },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "items" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "book" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "slug" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "images" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "large",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "medium",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "small",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "xl" },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  MyBooksByStatusQuery,
  MyBooksByStatusQueryVariables
>
export const MyCountByStatusDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "MyCountByStatus" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "me" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "currentlyReading" },
                  name: { kind: "Name", value: "libraryBooksByStatus" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "status" },
                      value: { kind: "EnumValue", value: "CURRENTLY_READING" },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "pagination" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "totalCount" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "read" },
                  name: { kind: "Name", value: "libraryBooksByStatus" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "status" },
                      value: { kind: "EnumValue", value: "READ" },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "pagination" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "totalCount" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "wantToRead" },
                  name: { kind: "Name", value: "libraryBooksByStatus" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "status" },
                      value: { kind: "EnumValue", value: "WANT_TO_READ" },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "pagination" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "totalCount" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "priority" },
                  name: { kind: "Name", value: "libraryBooksByStatus" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "status" },
                      value: { kind: "EnumValue", value: "PRIORITY" },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "pagination" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "totalCount" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "onHold" },
                  name: { kind: "Name", value: "libraryBooksByStatus" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "status" },
                      value: { kind: "EnumValue", value: "ON_HOLD" },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "pagination" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "totalCount" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "didNotFinish" },
                  name: { kind: "Name", value: "libraryBooksByStatus" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "status" },
                      value: { kind: "EnumValue", value: "DID_NOT_FINISH" },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "pagination" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "totalCount" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  MyCountByStatusQuery,
  MyCountByStatusQueryVariables
>
export const MyCustomShelvesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "MyCustomShelves" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "me" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                { kind: "Field", name: { kind: "Name", value: "username" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shelves" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "userId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "booksCount" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  MyCustomShelvesQuery,
  MyCustomShelvesQueryVariables
>
export const UserStatusCountsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "UserStatusCounts" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "username" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "userByUsername" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "username" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "username" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "currentlyReading" },
                  name: { kind: "Name", value: "libraryBooksByStatus" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "status" },
                      value: { kind: "EnumValue", value: "CURRENTLY_READING" },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "pagination" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "totalCount" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "read" },
                  name: { kind: "Name", value: "libraryBooksByStatus" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "status" },
                      value: { kind: "EnumValue", value: "READ" },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "pagination" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "totalCount" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "wantToRead" },
                  name: { kind: "Name", value: "libraryBooksByStatus" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "status" },
                      value: { kind: "EnumValue", value: "WANT_TO_READ" },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "pagination" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "totalCount" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "priority" },
                  name: { kind: "Name", value: "libraryBooksByStatus" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "status" },
                      value: { kind: "EnumValue", value: "PRIORITY" },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "pagination" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "totalCount" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "onHold" },
                  name: { kind: "Name", value: "libraryBooksByStatus" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "status" },
                      value: { kind: "EnumValue", value: "ON_HOLD" },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "pagination" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "totalCount" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "didNotFinish" },
                  name: { kind: "Name", value: "libraryBooksByStatus" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "status" },
                      value: { kind: "EnumValue", value: "DID_NOT_FINISH" },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "pagination" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "totalCount" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UserStatusCountsQuery,
  UserStatusCountsQueryVariables
>
export const BooksReadByUserDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "BooksReadByUser" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "username" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "userByUsername" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "username" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "username" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "libraryBooksByStatus" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "status" },
                      value: { kind: "EnumValue", value: "READ" },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "pagination" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "totalCount" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  BooksReadByUserQuery,
  BooksReadByUserQueryVariables
>
export const DiscoverUsersDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "DiscoverUsers" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "limit" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "discoverUsers" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "limit" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "limit" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "avatarId" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "username" } },
                { kind: "Field", name: { kind: "Name", value: "displayName" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "reviews" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "pagination" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "totalCount" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DiscoverUsersQuery, DiscoverUsersQueryVariables>
export const IsEmailTakenDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "IsEmailTaken" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "email" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "isEmailTaken" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "email" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "email" },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<IsEmailTakenQuery, IsEmailTakenQueryVariables>
export const MyAvatarDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "MyAvatar" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "me" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "avatarId" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MyAvatarQuery, MyAvatarQueryVariables>
export const MyBasicInfoDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "MyBasicInfo" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "me" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "avatarId" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "displayName" } },
                { kind: "Field", name: { kind: "Name", value: "username" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MyBasicInfoQuery, MyBasicInfoQueryVariables>
export const MyUsernameDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "MyUsername" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "me" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "username" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MyUsernameQuery, MyUsernameQueryVariables>
export const ProfileHeaderByUsernameDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "ProfileHeaderByUsername" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "username" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "userByUsername" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "username" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "username" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                { kind: "Field", name: { kind: "Name", value: "avatarId" } },
                { kind: "Field", name: { kind: "Name", value: "bannerId" } },
                { kind: "Field", name: { kind: "Name", value: "displayName" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "reviews" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "pagination" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "totalCount" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "libraryBooksByStatus" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "status" },
                      value: { kind: "EnumValue", value: "READ" },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "pagination" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "totalCount" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ProfileHeaderByUsernameQuery,
  ProfileHeaderByUsernameQueryVariables
>
export const ProfilePageDataByUsernameDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "ProfilePageDataByUsername" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "username" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "page" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "pageSize" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "sortBy" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "SortBy" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "userByUsername" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "username" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "username" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "UserFields" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "favoriteAuthors" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "FavAuthorFields" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "reviews" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "page" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "page" },
                      },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "pageSize" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "pageSize" },
                      },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "sortBy" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "sortBy" },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "items" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "FragmentSpread",
                              name: { kind: "Name", value: "ReviewFields" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "pagination" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "FragmentSpread",
                              name: {
                                kind: "Name",
                                value: "PaginationMetaFields",
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "favoriteBooks" },
            name: { kind: "Name", value: "userByUsername" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "username" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "username" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "favoriteBooks" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "FavBooksFields" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "popularReviews" },
            name: { kind: "Name", value: "userByUsername" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "username" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "username" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "username" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "reviews" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "page" },
                      value: { kind: "IntValue", value: "1" },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "pageSize" },
                      value: { kind: "IntValue", value: "2" },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "sortBy" },
                      value: { kind: "EnumValue", value: "MOST_LIKED" },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "items" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "FragmentSpread",
                              name: { kind: "Name", value: "ReviewFields" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "pagination" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "FragmentSpread",
                              name: {
                                kind: "Name",
                                value: "PaginationMetaFields",
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "recentReviews" },
            name: { kind: "Name", value: "userByUsername" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "username" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "username" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "username" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "reviews" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "page" },
                      value: { kind: "IntValue", value: "1" },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "pageSize" },
                      value: { kind: "IntValue", value: "2" },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "sortBy" },
                      value: { kind: "EnumValue", value: "NEWEST" },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "items" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "FragmentSpread",
                              name: { kind: "Name", value: "ReviewFields" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "pagination" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "FragmentSpread",
                              name: {
                                kind: "Name",
                                value: "PaginationMetaFields",
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "currentlyReading" },
            name: { kind: "Name", value: "userByUsername" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "username" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "username" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "libraryBooksByStatus" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "status" },
                      value: { kind: "EnumValue", value: "CURRENTLY_READING" },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "page" },
                      value: { kind: "IntValue", value: "1" },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "pageSize" },
                      value: { kind: "IntValue", value: "4" },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "items" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "book" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "title" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "slug" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "images" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "large",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "medium",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "small",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "xl" },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "read" },
            name: { kind: "Name", value: "userByUsername" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "username" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "username" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "libraryBooksByStatus" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "status" },
                      value: { kind: "EnumValue", value: "READ" },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "page" },
                      value: { kind: "IntValue", value: "1" },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "pageSize" },
                      value: { kind: "IntValue", value: "4" },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "items" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "book" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "title" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "slug" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "images" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "large",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "medium",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "small",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "xl" },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "wantToRead" },
            name: { kind: "Name", value: "userByUsername" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "username" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "username" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "libraryBooksByStatus" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "status" },
                      value: { kind: "EnumValue", value: "WANT_TO_READ" },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "page" },
                      value: { kind: "IntValue", value: "1" },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "pageSize" },
                      value: { kind: "IntValue", value: "4" },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "items" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "book" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "title" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "slug" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "images" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "large",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "medium",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "small",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "xl" },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "priority" },
            name: { kind: "Name", value: "userByUsername" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "username" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "username" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "libraryBooksByStatus" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "status" },
                      value: { kind: "EnumValue", value: "PRIORITY" },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "page" },
                      value: { kind: "IntValue", value: "1" },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "pageSize" },
                      value: { kind: "IntValue", value: "4" },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "items" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "book" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "title" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "slug" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "images" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "large",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "medium",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "small",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "xl" },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "onHold" },
            name: { kind: "Name", value: "userByUsername" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "username" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "username" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "libraryBooksByStatus" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "status" },
                      value: { kind: "EnumValue", value: "ON_HOLD" },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "page" },
                      value: { kind: "IntValue", value: "1" },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "pageSize" },
                      value: { kind: "IntValue", value: "4" },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "items" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "book" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "title" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "slug" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "images" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "large",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "medium",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "small",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "xl" },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "UserFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "User" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "__typename" } },
          { kind: "Field", name: { kind: "Name", value: "bio" } },
          { kind: "Field", name: { kind: "Name", value: "displayName" } },
          { kind: "Field", name: { kind: "Name", value: "insertedAt" } },
          { kind: "Field", name: { kind: "Name", value: "ratingsCount" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "socialLinks" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "instagram" } },
                { kind: "Field", name: { kind: "Name", value: "tiktok" } },
                { kind: "Field", name: { kind: "Name", value: "twitter" } },
                { kind: "Field", name: { kind: "Name", value: "website" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "totalRatingsCount" } },
          { kind: "Field", name: { kind: "Name", value: "averageRating" } },
          { kind: "Field", name: { kind: "Name", value: "username" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "FavAuthorFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Author" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "__typename" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "images" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "large" } },
                { kind: "Field", name: { kind: "Name", value: "small" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ReviewFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Review" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "__typename" } },
          { kind: "Field", name: { kind: "Name", value: "commentsCount" } },
          { kind: "Field", name: { kind: "Name", value: "content" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "insertedAt" } },
          { kind: "Field", name: { kind: "Name", value: "isEdited" } },
          { kind: "Field", name: { kind: "Name", value: "likesCount" } },
          { kind: "Field", name: { kind: "Name", value: "likedByMe" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "user" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "username" } },
                { kind: "Field", name: { kind: "Name", value: "avatarId" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "insertedAt" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "book" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "myReadingStatus" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "status" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "myShelves" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "booksCount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "userId" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "images" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "small" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "medium" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "large" } },
                      { kind: "Field", name: { kind: "Name", value: "xl" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "PaginationMetaFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "PaginationMeta" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "page" } },
          { kind: "Field", name: { kind: "Name", value: "pageSize" } },
          { kind: "Field", name: { kind: "Name", value: "totalCount" } },
          { kind: "Field", name: { kind: "Name", value: "totalPages" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "FavBooksFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Book" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "averageRating" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "myReadingStatus" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "status" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "originalPublicationDate" },
          },
          { kind: "Field", name: { kind: "Name", value: "positionInSeries" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "images" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "large" } },
                { kind: "Field", name: { kind: "Name", value: "medium" } },
                { kind: "Field", name: { kind: "Name", value: "small" } },
                { kind: "Field", name: { kind: "Name", value: "xl" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "ratingsCount" } },
          { kind: "Field", name: { kind: "Name", value: "reviewsCount" } },
          { kind: "Field", name: { kind: "Name", value: "seriesId" } },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ProfilePageDataByUsernameQuery,
  ProfilePageDataByUsernameQueryVariables
>
export const UserAvatarDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "UserAvatar" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "username" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "userByUsername" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "username" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "username" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "avatarId" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserAvatarQuery, UserAvatarQueryVariables>
export const UserIdByUsernameDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "UserIdByUsername" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "username" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "userByUsername" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "username" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "username" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UserIdByUsernameQuery,
  UserIdByUsernameQueryVariables
>
export const MeDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Me" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "me" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "avatarId" } },
                { kind: "Field", name: { kind: "Name", value: "bannerId" } },
                { kind: "Field", name: { kind: "Name", value: "bio" } },
                { kind: "Field", name: { kind: "Name", value: "displayName" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "ratingsCount" },
                },
                { kind: "Field", name: { kind: "Name", value: "username" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "socialLinks" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "instagram" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "website" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "tiktok" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "twitter" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "favoriteAuthors" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "favoriteBooks" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "images" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "large" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "medium" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "small" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "xl" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MeQuery, MeQueryVariables>
export const UsernameByUserIdDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "UsernameByUserId" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "user" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "id" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                { kind: "Field", name: { kind: "Name", value: "username" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UsernameByUserIdQuery,
  UsernameByUserIdQueryVariables
>
